import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import "./ResetPassword.scss"
// import Logo from "../../assets/img/logo.png"
import IllustrationAccess from "../../assets/img/access.png"
import { Oval } from 'react-loader-spinner'

function ResetPassword() {
    const { state } = useLocation();
    const navigate = useNavigate()

    const [visibleOld, setVisibleOld] = useState(false)
    const [visible, setVisible] = useState(false)
    const [visibleTwo, setVisibleTwo] = useState(false)

    const [userData, setUserData] = useState({
        email: state,
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    })
    const [fetchError, setFetchError] = useState(null)
    const [errors, setErrors] = useState({})

    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        })
    }

    const validateForm = (data) => {
        const errors = {}
        // email
        if(!data.email.trim()){
            errors.email = "Escriba el mail correctamente."
        } else if (!/\S+@\S+\.\S+/.test(data.email)){
            errors.email = "El email no es válido."
        }
        // old password
        if(!data.oldPassword.trim()) {
            errors.oldPassword = "Escriba su antigua contraseña."
        }
        // new password
        if(!data.newPassword.trim()) {
            errors.newPassword = "Escriba la nueva contraseña."
        } else if (!/^(?=.*[A-Z])(?=.*\d)(?=.{8,})/.test(data.newPassword)){
            errors.newPassword = "La contraseña debe contener una mayúscula, un símbolo y un número."
        } else if (data.newPassword !== data.confirmPassword){
            errors.newPassword = "Las contraseñas no coinciden."
        }
        // confirm password
        if(!data.confirmPassword.trim()) {
            errors.confirmPassword = "Repita la nueva contraseña."
        } else if (data.newPassword !== data.confirmPassword){
            errors.confirmPassword = "Las contraseñas no coinciden."
        }
        return errors;
    }

    const handleChangePassword = async (e) => {
        e.preventDefault()
        const newErrors = validateForm(userData)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            setFetchError(null)
            const obj = {
                mail: userData.email,
                passAntigua: userData.oldPassword,
                passNew: userData.newPassword,
                passNewValidator: userData.confirmPassword
            }
            try {
                setLoading(true)
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/user/newpassword`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                const data = await res.json()
                if(data.status === 0){
                    const data = {
                        codigo : 0,
                        mail: userData.email
                    }
                    setLoading(false)
                    navigate("/", {state: data})
                } else {
                    // Error 20 --> El usuario no necesita cambio de contraseña.
                    // Error 10 --> Contraseñas no coinciden.
                    // Error 99 --> La contraseña no cumple con que sea de minimo 8 de longitud, o que tenga una mayuscula o un numero.
                    // Error 99 --> La contraseña antigua es incorrecta.
                    setLoading(false)
                    setFetchError(data.codeError)
                } 
            } catch (error) {
                setFetchError("Error: Conexión con backend.")
            }
        } else {
            setFetchError("Completar los campos mencionados.")
        }
    }

    return (
        <>
            {loading && <div className='loading__reset vh-100 vw-100 z-3 position-absolute d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#fff"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='text-white fw-medium'>Loading...</p>
                </div>
            }
            <div className='reset__password vh-100 d-flex align-items-center justify-content-center'>
                <div className='reset__password__container d-flex flex-row bg-white shadow-sm rounded'>
                    <div className='reset__password__container__info d-none d-md-flex flex-column align-items-center justify-content-center col-md-6'>
                        <img className='mb-4' src={IllustrationAccess} alt="" />
                        <i className="bi bi-exclamation-circle"></i>
                        <h3 className='text-center'>Hemos detectado que es necesario un cambio de contraseña.</h3>
                    </div>
                    <div className='reset__password__container__form col-12 col-md-6 d-flex flex-column align-items-center justify-content-center'>
                        <div className='reset__password__container__form__title d-flex flex-column align-items-center d-md-none'>
                            <i className="bi bi-exclamation-circle"></i>
                            <h3 className='text-center'>Hemos detectado que es necesario un cambio de contraseña.</h3>
                        </div>
                        <form className='w-100 d-flex flex-column align-items-center' onSubmit={handleChangePassword}>
                            <div className="w-100 d-flex flex-column mb-3">
                                <label htmlFor="email" className="form-label">Correo</label>
                                <input 
                                    onChange={handleChange} 
                                    type="email" 
                                    id="email" 
                                    name="email" 
                                    className="form-control"
                                    value={userData.email}
                                />
                                {errors.email && <span className='form__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.email}</span>}
                            </div>
                            <div className='w-100 d-flex flex-column mb-3'>
                                <label htmlFor="password" className='form-label'>Contraseña antigua</label>
                                <div className='d-flex flex-row align-items-center input--fake'>
                                    <input 
                                        onChange={handleChange} 
                                        type={visibleOld ? "text" : "password"}
                                        id='oldPassword' 
                                        name="oldPassword" 
                                        placeholder='' 
                                        className='form-control input--none border-0'
                                        value={userData.oldPassword}
                                    />
                                    <div className='input--icon' onClick={()=>setVisibleOld(!visibleOld)}>
                                        {visibleOld ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                    </div>
                                </div>
                                {errors.oldPassword && <span className='form__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.oldPassword}</span>}
                            </div>
                            <div className='w-100 d-flex flex-column mb-3'>
                                <label htmlFor="password" className='form-label'>Nueva contraseña</label>
                                <div className='d-flex flex-row align-items-center input--fake'>
                                    <input 
                                        onChange={handleChange} 
                                        type={visible ? "text" : "password"}
                                        id='newPassword' 
                                        name="newPassword" 
                                        placeholder='' 
                                        className='form-control input--none border-0'
                                        value={userData.newPassword}
                                    />
                                    <div className='input--icon' onClick={()=>setVisible(!visible)}>
                                        {visible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                    </div>
                                </div>
                                {errors.newPassword && <span className='form__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.newPassword}</span>}
                            </div>
                            <div className='w-100 d-flex flex-column mb-4'>
                                <label htmlFor="password" className='form-label'>Repetir contraseña</label>
                                <div className='d-flex flex-row align-items-center input--fake'>
                                    <input 
                                        onChange={handleChange} 
                                        type={visibleTwo ? "text" : "password"}
                                        id='confirmPassword' 
                                        name="confirmPassword" 
                                        placeholder='' 
                                        className='form-control input--none border-0'
                                        value={userData.confirmPassword}
                                    />
                                    <div className='input--icon' onClick={()=>setVisibleTwo(!visibleTwo)}>
                                        {visibleTwo ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                                    </div>
                                </div>
                                {errors.confirmPassword && <span className='form__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.confirmPassword}</span>}
                            </div>
                            <div>
                                {fetchError ? <div className='mb-4'><i className="bi bi-exclamation-circle pe-2 text-danger"></i><span className='text-danger'>{fetchError}</span></div> : ""}
                            </div>
                            <button type="submit" className="btn btn-primary">Cambiar contraseña</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword