import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import { jwtDecode } from "jwt-decode"
import "./AreasOkr.scss"
import { ProgressBar } from 'react-bootstrap';
import CarruselOkrByEmpresa from '../../components/CarruselOkrByEmpresa'
import ModalNewOkrPro from '../../components/Modales/ModalNewOkrPro'

import { usePlanes } from '../../context/PlanesContext'
import { OkrContext } from '../../context/OkrContext'

export const areasOkrContext = React.createContext()

function AreasOkr() {
    const { empresa, fetchEmpresa, fetchEmpresaSinConsultora } = usePlanes()
    const { fetchOkrByEmpresa } = useContext(OkrContext)
    
    const { idConsult, id } = useParams()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [areas, setAreas] = useState([])

    const [loadingOkr, setLoadingOkr] = useState(false)
    const [errorOkr, setErrorOkr] = useState(null)
    const [okrsEmpresa, setOkrsEmpresa] = useState([])

    const [modalNewOkr, setModalNewOkr] = useState(false)

    const [promedioEmpresas, setPromedioEmpresas] = useState(0)

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    useEffect(() => {
        const fetchAreas = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        user: USER,
                        idEmpresa: id
                    })
                })
                const data = await res.json()
                if(data.status !== 0){
                    setError(data.codeError)
                    setLoading(false)
                } else {
                    setAreas(data.objeto)
                    setLoading(false)
                }
            } catch (error) {
                setError("Error: Conexión con backend.")
                setLoading(false)
            }
        }
    
        fetchAreas()

        fetchOkrByEmpresa(USER, id)
        .then(res => {
            if(res.error !== 0){
                setLoadingOkr(false)
                setErrorOkr(res.errorDetalle)
            } else {
                setLoadingOkr(false)
                setOkrsEmpresa(res.objeto)
                // promedio de okrs totales
                const arrFiltrado = res.objeto.reduce((sum, item) => sum + Math.round(parseInt(item.progresoGeneral)), 0)
                const promedio = res.objeto.length > 0 ? arrFiltrado / res.objeto.length : 0
                setPromedioEmpresas(promedio)
            }
        })

        if(parseInt(idConsult) === 0){
            fetchEmpresaSinConsultora(USER, id)
        } else {
            fetchEmpresa(USER, idConsult, id)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNewOkr = (e) => {
        e.preventDefault()
        setModalNewOkr(true)
    }

    let ruta;
    if(USER.puesto === 1){
        ruta = `/consultora/${idConsult}/empresa/${id}`
    }
    if(USER.puesto === 2){
        ruta = `/empresa/${id}`
    }

    return (
        <>
            <areasOkrContext.Provider value={{setLoadingOkr, setErrorOkr, setOkrsEmpresa}}>
                <ModalNewOkrPro show={modalNewOkr} onHide={()=>setModalNewOkr(false)} />
                <div className='areas section'>
                    <div className='section__header d-flex flex-row align-items-end mb-4'>
                        <i className='bi bi-list-check me-2'></i>
                        <h4 className='m-0'><Link className='breadcrumb__generico' to={'/okr'}>OKR</Link> <i className="bi bi-chevron-right"></i> {empresa}</h4>
                    </div>
                    <div className='areas__main'>
                        {/* Botón de agregar OKR */}
                        {(USER.puesto === 1 || USER.puesto === 2) && <div className='okr__title mb-4 d-flex flex-row align-items-center justify-content-between'>
                            <h3 className='m-0 text-blue-500'>Listado de Okr's</h3>
                            <button 
                                className='btn__newOkr btn btn-primary rounded-pill shadow-sm fw-medium' 
                                onClick={handleNewOkr}
                            >
                                Agregar OKR
                            </button>
                            </div>
                        }
                        <div className='areas__main__container scroll--y'>
                            <div className='lista__okr--empresa'>
                                {loadingOkr ? (
                                    <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                        <Oval
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#0d6efd"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        <p className='fw-medium'>Loading...</p>
                                    </div>
                                ) : (
                                    <>
                                        {errorOkr ? (
                                            <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                                <h3 className='text-danger mb-1'>Oops!</h3>
                                                <p className='m-0'>{errorOkr}</p>
                                            </div>
                                        ) : (
                                            <>
                                                {okrsEmpresa.length === 0 ? (
                                                    <div className='okr--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                                                        <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes OKR's de la empresa para mostrar aún.</h3>
                                                        <p className='mb-0 text-center'>Ingresa a una de las áreas y crea tu primer okr.</p>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className='row mb-3 d-flex flex-row justify-content-between align-items-center'>
                                                            <h4 className='col-6 m-0 text-blue-500'>Objetivos de la empresa</h4>
                                                            {/* <div className="col-3">
                                                                <select className="form-select form-select-sm" id="year" name="year">
                                                                    <option value="">Filtrar por año</option>
                                                                    <option value="2023">2023</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                </select>
                                                            </div> */}
                                                        </div>
                                                        <div className='mb-4'>
                                                            <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(promedioEmpresas)} label={`${Math.round(promedioEmpresas)}%`} max={100}/>
                                                            <CarruselOkrByEmpresa items={okrsEmpresa} user={USER}/>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='areas__container'>
                                {loading ? (
                                    <div className='loading__areas d-flex flex-column align-items-center justify-content-center'>
                                        <Oval
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#0d6efd"
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                        <p className='fw-medium'>Loading...</p>
                                    </div>
                                ) : (
                                    <>
                                        {error ? (
                                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                                <h2 className='text-blue-500 fw-semibold mb-2'>Oops!</h2>
                                                <p>{error}</p>
                                            </div>
                                        ) : (
                                            <>
                                                <h4 className='mb-3 text-blue-500'>Áreas de la empresa</h4>
                                                {areas.length === 0 ? (
                                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                                        <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes áreas asignadas.</h3>
                                                        <p className='text-center'>Contactar al equipo de soporte para el manejo de áreas.</p>
                                                    </div>
                                                ) : (
                                                    <div className='areas__list mb-4'>
                                                        {areas.map((e,i) => {
                                                            return <Link 
                                                                to={`/okr${ruta}/area/${e.id_area}`} 
                                                                className="btn areas__list__area border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center" 
                                                                key={i}
                                                            >
                                                            <h4 className='p-0 m-0 text-start'>{e.nombre_del_Area}</h4>
                                                            </Link>
                                                        })}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </areasOkrContext.Provider>
        </>
    )
}

export default AreasOkr