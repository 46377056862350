import React, { useEffect, useState, useContext } from 'react'
import { Link } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import { ProgressBar } from 'react-bootstrap';
import Carrusel from '../Carrusel';
import CarruselOkrByEmpresa from "../../components/CarruselOkrByEmpresa"
import "./ListaAreasOkr.scss"
import ModalNewOkrPersonal from '../Modales/ModalNewOkrPersonal';

import { OkrContext } from '../../context/OkrContext';

export const PersonalContext = React.createContext()

function ListaAreasOkr(props) {

  const { fetchOkrByEmpresa, fetchOkrPersonales } = useContext(OkrContext)

  const [loading, setLoading] = useState(true)
  const [areas, setAreas] = useState([]);
  const [errorAreas, setErrorAreas] = useState(null)

  const [loadingOkr, setLoadingOkr] = useState(false)
  const [errorOkr, setErrorOkr] = useState(null)
  const [okrsEmpresa, setOkrsEmpresa] = useState([])

  const [loadingPersonales, setLoadingPersonales] = useState(true)
  const [errorOkrPersonales, setErrorOkrPersonales] = useState(null)
  const [okrPersonales, setOkrPersonales] = useState([])

  const [modalNewOkr, setModalNewOkr] = useState(false)

  const [promedioPersonal, setPromedioPersonal] = useState(0)
  const [promedioEmpresa, setPromedioEmpresa] = useState(0)

  useEffect(() => {
    // OKR por empresa
    fetchOkrByEmpresa(props.user, props.user.idEmpresa)
    .then(res => {
        if(res.error !== 0){
            setLoadingOkr(false)
            setErrorOkr(res.errorDetalle)
        } else {
            setLoadingOkr(false)
            setOkrsEmpresa(res.objeto)
            // promedio de okrs totales
            const arrFiltrado = res.objeto.reduce((sum, item) => sum + Math.round(parseInt(item.progresoGeneral)), 0)
            const promedio = res.objeto.length > 0 ? arrFiltrado / res.objeto.length : 0
            setPromedioEmpresa(promedio)
        }
    })
    // OKR personales
    fetchOkrPersonales(props.user)
    .then(res => {
        if(res.error !== 0){
            setLoadingPersonales(false)
            setErrorOkrPersonales(res.errorDetalle)
        } else {
            setLoadingPersonales(false)
            setOkrPersonales(res.objeto)
            // promedio de okrs totales
            const arrFiltrado = res.objeto.reduce((sum, item) => sum + Math.round(parseInt(item.progresoGeneral)), 0)
            const promedio = res.objeto.length > 0 ? arrFiltrado / res.objeto.length : 0
            setPromedioPersonal(promedio)
        }
    })
    // OKR por áreas
    const fetchAreas = async () => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user: props.user,
            })
          })
          const data = await res.json()
          if(data.status !== 0){
            setErrorAreas(data.codeError)
            setLoading(false)
          } else {
            setAreas(data.objeto)
            setLoading(false)
          }
        } catch (error) {
          setErrorAreas("Error: Conexión con backend.")
          setLoading(false)
        }
    }

    fetchAreas()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNewOkr = () => {
    setModalNewOkr(true)
  }

  return (
    <>
      <PersonalContext.Provider value={{setLoadingPersonales, setErrorOkrPersonales, setOkrPersonales, setLoadingOkr, setErrorOkr, setOkrsEmpresa}}>
        <ModalNewOkrPersonal show={modalNewOkr} onHide={()=>setModalNewOkr(false)} />
        <div className='listaAreas__okr--container'>
          <div className='okr__title mb-4 d-flex flex-row align-items-center justify-content-between'>
            <h3 className='m-0 text-blue-500'>Listado de Okr's</h3>
            <button 
              className='btn__newOkr btn btn-primary rounded-pill shadow-sm fw-medium' 
              onClick={handleNewOkr}
            >
              Agregar OKR
            </button>
          </div>
          <div className='listaAreas__okr scroll--y'>
            {/* CARRUSEL OKR PERSONALES */}
            <div className='okr__main__personales'>
              {loadingPersonales ? (
                <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                  <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#0d6efd"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
                  <p className='fw-medium'>Loading...</p>
                </div>
              ) : (
                <>
                  {errorOkrPersonales ? (
                    <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                      <h3 className='text-danger mb-1'>Oops!</h3>
                      <p className='m-0'>{errorOkrPersonales}</p>
                    </div>
                  ) : (
                    <>
                      <div className='row mb-3 d-flex flex-row justify-content-between align-items-center'>
                        <h4 className='col-6 m-0 text-blue-500'>Objetivos personales</h4>
                        {/* <div className="col-3">
                            <select className="form-select form-select-sm" id="year" name="year">
                                <option value="">Filtrar por año</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                            </select>
                        </div> */}
                      </div>
                      {okrPersonales.length === 0 ? (
                        <div className='okr--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                          <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes OKR's personales para mostrar aún.</h3>
                          <p className='mb-0 text-center'>Para comenzar, ingresa tu primer OKR.</p>
                        </div>
                      ) : (
                      <div className='mb-4'>
                        <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(promedioPersonal)} label={`${Math.round(promedioPersonal)}%`} max={100}/>
                        <Carrusel items={okrPersonales} user={props.user}/>
                      </div>
                      )}
                    </>
                  )}
                </>
              )} 
            </div>
            {/* CARRUSEL OKR BY EMPRESA */}
            <div className='lista__okr--empresa'>
                {loadingOkr ? (
                    <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                        <Oval
                            visible={true}
                            height="80"
                            width="80"
                            color="#0d6efd"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <p className='fw-medium'>Loading...</p>
                    </div>
                ) : (
                    <>
                        {errorOkr ? (
                            <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                <h3 className='text-danger mb-1'>Oops!</h3>
                                <p className='m-0'>{errorOkr}</p>
                            </div>
                        ) : (
                            <>
                                <div className='row mb-3 d-flex flex-row justify-content-between align-items-center'>
                                    <h4 className='col-6 m-0 text-blue-500'>Objetivos de la empresa</h4>
                                    {/* <div className="col-3">
                                        <select className="form-select form-select-sm" id="year" name="year">
                                            <option value="">Filtrar por año</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                        </select>
                                    </div> */}
                                </div>
                                {okrsEmpresa.length === 0 ? (
                                    <div className='okr--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                                    <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes OKR's de la empresa para mostrar aún.</h3>
                                    {(props.user.puesto < 5 && props.user.puesto >= 1) && (
                                      <p className='mb-0 text-center'>Ingresa a una de las áreas y crea tu primer okr.</p>
                                    )}
                                    </div>
                                ) : (
                                    <div className='mb-4'>
                                        <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(promedioEmpresa)} label={`${Math.round(promedioEmpresa)}%`} max={100}/>
                                        <CarruselOkrByEmpresa items={okrsEmpresa} user={props.user}/>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            {/* LISTADO DE AREAS */}
            <div>
              {loading ? (
                  <div className='loading__areas d-flex flex-column align-items-center justify-content-center'>
                  <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="#0d6efd"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
                  <p className='fw-medium'>Loading...</p>
                  </div>
              ) : (
                  <>
                    {errorAreas ? (
                      <div className='d-flex flex-column align-items-center justify-content-center rounded-3'>
                        <h3 className='text-danger fw-semibold mb-1 text-center'>Oops!</h3>
                        <p className='text-center'>{errorAreas}</p>
                      </div>
                    ) : (
                      <>
                        <h4 className='mb-3 text-blue-500'>Áreas de la empresa</h4>
                        {areas.length === 0 ? (
                          <div className='d-flex flex-column align-items-center justify-content-center rounded-3'>
                            <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes áreas asignadas.</h3>
                            <p className='text-center'>Contactar al equipo de soporte para el manejo de áreas.</p>
                          </div>
                        ) : (
                          <div className='areas__list mb-4'>
                              {areas.map((e,i) => {
                                  return <Link to={`/okr/area/${e.id_area}`} className="btn areas__list__area border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center" key={i}>
                                      <h4 className='p-0 m-0 text-start'>{e.nombre_del_Area}</h4>
                                  </Link>
                              })}
                          </div>
                        )}
                      </>
                    )}
                  </>
              )}
            </div>
          </div>
        </div>
      </PersonalContext.Provider>
    </>
  )
}

export default ListaAreasOkr