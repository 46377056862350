import React from 'react'
import { jwtDecode } from "jwt-decode"
import "./HomeDatIN.scss"
import ListaConsultorasDatIN from '../../components/FormDatIN/NuevaEstructura/ListaConsultorasDatIN'
import ListaEmpresasDatIN from '../../components/FormDatIN/NuevaEstructura/ListaEmpresasDatIN'
import ListaAreasDatIN from '../../components/FormDatIN/NuevaEstructura/ListaAreasDatIN'
import DatIN from './DatIN'

function HomeDatIN() {
    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const title = (
        <>
            <div className='section__header d-flex flex-row align-items-end mb-4'>
                <i className='bi bi-bar-chart-fill me-2'></i>
                <h4 className='m-0'>DatIN</h4>
            </div>
        </>
    )

    if(USER.puesto === 1) {
        return (
            <div className='home__DatIN section'>
                {title}
                <ListaConsultorasDatIN user={USER}/>
            </div>
        )
    }

    if(USER.puesto === 2) {
        return (
            <div className='home__DatIN section'>
                {title}
                <ListaEmpresasDatIN user={USER}/>
            </div>
        )
    }

    if(USER.puesto === 3) {
        return (
            <div className='home__DatIN section'>
                {title}
                <ListaAreasDatIN user={USER}/>
            </div>
        )
    }

    if(USER.puesto === 4 || USER.puesto === 5){
        return (
            <DatIN />
        )
    }
}

export default HomeDatIN