import React, { useState, useEffect, useContext, useRef } from 'react'
import { ProgressBar, Modal } from 'react-bootstrap';
import ModalSubtarea from './Modales/PlanesAuditoria/ModalSubtarea';
import { tareasAuditContext } from './TareasAudit';
import { Oval } from 'react-loader-spinner'
import ModalVerSubtarea from './Modales/PlanesAuditoria/ModalVerSubtarea';
import "./TareasAudit.scss"
import { CSSTransition } from 'react-transition-group';
import MuestrasAudit from './MuestrasAudit';
import IcoList from "../assets/img/ico-list.svg"

export const subtareasAuditContext = React.createContext()

function SubtareasAudit() {
  const {
    subtareas, 
        loadingSub, 
        setLoadingSub, 
        errorSub, 
        setErrorSub, 
        fetchSubtareasById, 
        setSubtareas, 
        idTask, 
        fetchMetrica, 
        idCicloSelec, 
        setTareasRealporCiclo, 
        setTareasNorealporCiclo, 
        setLoadingTar, 
        fetchTareasById, 
        setErrorTar, 
        tareasByCiclo,
        setTareasByCiclo, 
        expandedRowMuestra, 
        setExpandedRowMuestra,
        muestras,
        setMuestras,
        idSubtask, 
        setIdSubtask
  } = useContext(tareasAuditContext)

  const [modalSubtarea, setModalSubtarea] = useState(false)
  const [subtareaObj, setSubtareaObj] = useState(null)
  const [modalDeleteSub, setModalDeleteSub] = useState(false)
  const [errorDel, setErrorDel] = useState(null)
  const [modalFinalizar, setModalFinalizar] = useState(false)
  const [modalVerSub, setModalVerSub] = useState(false)
  const [errorFin, setErrorFin] = useState(null)

  const [loadingMuestra, setLoadingMuestra] = useState(false)
  const [errorMuestra, setErrorMuestra] = useState(null)

  const [tareaSelec, setTareaSelec] = useState(null)

  useEffect(()=> {
    if(tareasByCiclo){
        const obj = tareasByCiclo.find((e) => e.id_tareaPA === idTask)
        setTareaSelec(Math.round(obj.progreso_tarea))
    }
  },[idTask, tareasByCiclo])

  const handleEditSubtarea = (id) => {
    const obj = subtareas.find((e) => e.id_subTareaPA === id)
    setSubtareaObj(JSON.stringify(obj))
    setModalSubtarea(true)
  }

  const handleNewSubtarea = (e) => {
    e.preventDefault()
    setModalSubtarea(true)
  }

  // Delete subtarea
  const closeDeleteSubtarea = () => {
    setModalDeleteSub(false)
    setErrorDel(null)
  }

  const handleModalDelete = (id) => {
    setIdSubtask(id)
    setModalDeleteSub(true)
  }

  const handleDeleteSubtarea = async () => {
    const obj = {
        id_subtarea: parseInt(idSubtask)
    }
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/auditoria/deleteSubTask`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(obj)
        })
        const data = await res.json()
        if(data.error !== 0){
            setErrorDel(data.errorDetalle)
        } else {
            setModalDeleteSub(false)
            // Actualizar métricas
            fetchMetrica()
            .then(res => {
                if(res.error !== 0){
                    console.log(res.errorDetalle)
                } else {
                    let tareasNorealizadas = 0;
                    const arr = res.objeto
                    const selec = arr.find(e => e.id_cicloPA === idCicloSelec)
                    if(selec === undefined) {
                        setTareasRealporCiclo(0)
                        setTareasNorealporCiclo(0)
                    } else {
                        tareasNorealizadas = selec.tareas_totales - selec.tareas_realizadas
                        setTareasNorealporCiclo(tareasNorealizadas)
                        setTareasRealporCiclo(selec.tareas_realizadas)
                    } 
                }
            })
            // actualiza tareas
            setLoadingTar(true)
            fetchTareasById(idCicloSelec)
            .then(res => {
                if(res.error !== 0){
                    setLoadingTar(false)
                    setErrorTar(res.errorDetalle)
                } else {
                    setLoadingTar(false)
                    setTareasByCiclo(res.objeto)
                }
            })
            // fin de actualiza tareas
            // actualiza subtareas
            setLoadingSub(true)
            fetchSubtareasById(idTask)
            .then(res => {
                if(res.error !== 0){
                    setLoadingSub(false)
                    setErrorSub(res.errorDetalle)
                } else {
                    setLoadingSub(false)
                    setSubtareas(res.objeto)
                }
            })
        }
    } catch (error) {
        setErrorDel("Error: Conexión con backend.")
    }
  }

  // Finalizar subtarea
  const closeFinalizarSubtarea = () => {
    setModalFinalizar(false)
    setErrorFin(null)
  }

  const handleModalFinalizar = (id) => {
    setIdSubtask(id)
    setModalFinalizar(true)
  }

  const handleFinalizarSubtarea = async () => {
    const obj = subtareas.find((e) => e.id_subTareaPA === idSubtask)
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/auditoria/subTareaok`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
              subtarea: obj
            })
          })
          const data = await res.json()
          if(data.error !== 0){
            setErrorFin(data.errorDetalle)
          } else {
            setModalFinalizar(false)
            // Actualizar métricas
            fetchMetrica()
            .then(res => {
                if(res.error !== 0){
                    console.log(res.errorDetalle)
                } else {
                    let tareasNorealizadas = 0;
                    const arr = res.objeto
                    const selec = arr.find(e => e.id_cicloPA === idCicloSelec)
                    if(selec === undefined) {
                        setTareasRealporCiclo(0)
                        setTareasNorealporCiclo(0)
                    } else {
                        tareasNorealizadas = selec.tareas_totales - selec.tareas_realizadas
                        setTareasNorealporCiclo(tareasNorealizadas)
                        setTareasRealporCiclo(selec.tareas_realizadas)
                    } 
                }
            })
            // actualiza tareas
            setLoadingTar(true)
            fetchTareasById(idCicloSelec)
            .then(res => {
                if(res.error !== 0){
                    setLoadingTar(false)
                    setErrorTar(res.errorDetalle)
                } else {
                    setLoadingTar(false)
                    setTareasByCiclo(res.objeto)
                }
            })
            // fin de actualiza tareas
            // actualiza subtareas
            setLoadingSub(true)
            fetchSubtareasById(idTask)
            .then(res => {
                if(res.error !== 0){
                    setLoadingSub(false)
                    setErrorSub(res.errorDetalle)
                } else {
                    setLoadingSub(false)
                    setSubtareas(res.objeto)
                }
            })
            // actualiza muestras
            setLoadingMuestra(true)
            fetchMuestrasById(idSubtask)
            .then(res => {
                if(res.error !== 0){
                    setLoadingMuestra(false)
                    setErrorMuestra(res.errorDetalle)
                } else {
                    setLoadingMuestra(false)
                    setMuestras(res.objeto)
                }
            })
          }
    } catch (error) {
        setErrorFin("Error: Conexión con backend.")
    }
  }

  // Ver subtarea
  const handleShowInfo = (id) => {
    const obj = subtareas.find((e) => e.id_subTareaPA === id)
    setSubtareaObj(JSON.stringify(obj))
    setModalVerSub(true)
  }

  // 
  const muestraRef = useRef()

  const fetchMuestrasById = async (id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/auditoria/viewMuestras`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                idSubTarea: id
            })
        })
        const data = await res.json()
        return data
    } catch (error) {
        setErrorMuestra("Error: Conexión con backend.")
    }
  }

  const handleMuestrasById = async (id) => {
    setExpandedRowMuestra(expandedRowMuestra === id ? null : id)
    fetchMuestrasById(id)
    .then(res => {
        if(res.error !== 0){
            setLoadingMuestra(false)
            setErrorMuestra(res.errorDetalle)
        } else {
            setLoadingMuestra(false)
            setMuestras(res.objeto)
        }
    })
    setIdSubtask(id)
  }

  return (
    <>
      <subtareasAuditContext.Provider value={{ subtareaObj, setSubtareaObj, setLoadingSub, setErrorSub, fetchSubtareasById, subtareas, setSubtareas, idTask, fetchMetrica, idCicloSelec, setTareasRealporCiclo, setTareasNorealporCiclo, setLoadingTar, fetchTareasById, setErrorTar, setTareasByCiclo, muestras, setMuestras, loadingMuestra, setLoadingMuestra, errorMuestra, setErrorMuestra, idSubtask, setIdSubtask, fetchMuestrasById }}>
        <ModalSubtarea show={modalSubtarea} onHide={()=>setModalSubtarea(false)} />
        <ModalVerSubtarea show={modalVerSub} onHide={()=>setModalVerSub(false)} />
        {/* Modal Eliminar subtarea */}
        <Modal className='modal__delete' show={modalDeleteSub} onHide={closeDeleteSubtarea} backdrop="static" centered>
            <Modal.Header closeButton>
            <Modal.Title><h3>Eliminar subtarea</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro de eliminar esta subtarea?</Modal.Body>
            <Modal.Footer className='d-flex flex-column'>
            <div className='d-flex flex-row align-items-center align-self-end'>
                <button className='btn btn-secondary rounded-pill me-2' onClick={closeDeleteSubtarea}>Cancelar</button>
                <button className='btn btn-danger rounded-pill' onClick={handleDeleteSubtarea}>Borrar</button>
            </div>
            {errorDel && <p>{errorDel}</p>}
            </Modal.Footer>
        </Modal>
        {/* Modal finalizar subtarea */}
        <Modal className='modal__delete' show={modalFinalizar} onHide={closeFinalizarSubtarea} backdrop="static" centered>
            <Modal.Header closeButton>
            <Modal.Title><h3>Finalizar subtarea</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea finalizar esta subtarea?</Modal.Body>
            <Modal.Footer className='d-flex flex-column'>
            <div className='d-flex flex-row align-items-center align-self-end'>
                <button className='btn btn-secondary rounded-pill me-2' onClick={closeFinalizarSubtarea}>Cancelar</button>
                <button className='btn btn-danger rounded-pill' onClick={handleFinalizarSubtarea}>Finalizar</button>
            </div>
            {errorFin && <p>{errorFin}</p>}
            </Modal.Footer>
        </Modal>
        {loadingSub ? (
          <div className='loading__subtareas d-flex flex-row align-items-center'>
              <div className='me-2'>
                  <Oval
                      visible={true}
                      height="20"
                      width="20"
                      color="#0d6efd"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                  />
              </div>
              <p className='fw-medium m-0'>Loading...</p>
          </div>
        ) : (
          <>
            {errorSub ? (
              <p>{errorSub}</p>
            ) : (
              <>
                {subtareas.length === 0 ? (
                  <div className='d-flex flex-row'>
                    <div className='icon__lista__task d-flex justify-content-end'>
                        <img src={IcoList} alt=''/>
                    </div>
                    <div className='table__custom__row--btnadd d-flex flex-row align-items-center'>
                      {tareaSelec !== null && (
                        <>
                          <button onClick={handleNewSubtarea} 
                              className={`${tareaSelec === 100 ? "disabled" : ""} btn btn-outline-primary btn-sm rounded-pill px-3 fw-medium`}>
                              <i className="bi bi-plus me-1"></i>
                                  Crear una subtarea
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {subtareas.map((s, i) => {
                      return <React.Fragment key={i}>
                        <div className='d-flex flex-row'>
                          <div className='icon__lista__task d-flex justify-content-end'>
                            <img src={IcoList} alt=''/>
                          </div>
                          {/* Row de cada subtarea */}
                          <div className='table__custom__row bggray100' key={s.id_subTareaPA}>
                            <div className='table__custom__cell'>
                                <button className='btn__ico btn p-0' onClick={()=>handleMuestrasById(s.id_subTareaPA)}>
                                    {expandedRowMuestra === s.id_subTareaPA ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                                </button>
                            </div>
                            <div className='table__custom__cell cell__buttons'>
                              {Math.round(s.progreso_tarea) === 100 ? (
                                  <>
                                      <button onClick={()=> handleShowInfo(s.id_subTareaPA)} className='btn__ico--g btn border-0 p-0'><i className="bi bi-eye"></i></button>
                                      <button className='disabled btn__ico--g btn border-0 p-0'><i className="bi bi-check-square"></i></button>
                                      <button className='disabled btn__ico--g btn border-0 p-0'><i className="bi bi-pencil"></i></button>
                                      <button className='disabled btn__ico--g btn border-0 p-0'><i className="bi bi-trash3"></i></button>
                                  </>
                              ): (
                                  <>
                                      <button onClick={()=> handleShowInfo(s.id_subTareaPA)} className='btn__ico--g btn border-0 p-0'><i className="bi bi-eye"></i></button>
                                      <button onClick={()=> handleModalFinalizar(s.id_subTareaPA)} className='btn__ico--g btn border-0 p-0'><i className="bi bi-square"></i></button>
                                      <button onClick={()=> handleEditSubtarea(s.id_subTareaPA)} className='btn__ico--g btn btn__edit--icon border-0 p-0'><i className="bi bi-pencil"></i></button>
                                      <button onClick={()=> handleModalDelete(s.id_subTareaPA)} className='btn__ico--g btn btn__delete--icon border-0 p-0'><i className="bi bi-trash3"></i></button>
                                  </>
                              )} 
                            </div>
                            <div className='table__custom__cell cell__orden'></div>
                            <div className='table__custom__cell cell__nombre'>{s.titulo}</div>
                            <div className='table__custom__cell cell__prioridad'>
                                {s.prioridad === 1 && <span className='table__tbody__prioridad--baja rounded-pill text-white badge'>baja</span>}
                                {s.prioridad === 2 && <span className='table__tbody__prioridad--media rounded-pill text-white badge'>media</span>}
                                {s.prioridad === 3 && <span className='table__tbody__prioridad--alta rounded-pill text-white badge'>alta</span>}
                            </div>
                            <div className='table__custom__cell cell__estado'>
                                {s.estado === 1 && <span className='table__tbody__estado--pendiente rounded-pill text-white badge'>Pendiente</span>}
                                {s.estado === 2 && <span className='table__tbody__estado--proceso rounded-pill text-white badge'>En proceso</span>}
                                {s.estado === 3 && <span className='table__tbody__estado--completada rounded-pill text-white badge'>Completada</span>}
                                {s.estado === 4 && <span className='table__tbody__estado--espera rounded-pill text-white badge'>En espera</span>}
                                {s.estado === 5 && <span className='table__tbody__estado--cancelada rounded-pill text-white badge'>Cancelada</span>}
                                {s.estado === 6 && <span className='table__tbody__estado--bloqueada rounded-pill text-white badge'>Bloqueada</span>}
                            </div>
                            <div className='table__custom__cell cell__progreso'>
                                <ProgressBar className='table__tbody__progreso__bar' now={Math.round(s.progreso_tarea)} label={`${Math.round(s.progreso_tarea)}%`} max={100}/>
                            </div>
                            <div className='table__custom__cell cell__horas'>{s.horas_tarea}</div>
                            <div className="table__custom__cell cell__notas">{s.notas}</div>
                            <div className="table__custom__cell cell__mail">{s.nombreUser}</div>
                            <div className="table__custom__cell cell__date">{s.fecha_inicio.replace(/-/g, '/').split("/").reverse().join("/")}</div>
                            <div className="table__custom__cell cell__date">
                                {Math.round(s.avance) === 100 ? `${s.fecha_final.replace(/-/g, '/').split("/").reverse().join("/")}` : ""}
                            </div>
                          </div>
                        </div>
                        <CSSTransition
                            in={expandedRowMuestra === s.id_subTareaPA}
                            timeout={100}
                            classNames="details"
                            unmountOnExit
                            nodeRef={muestraRef}
                        >
                          <div ref={muestraRef}>
                              <MuestrasAudit />
                          </div>
                        </CSSTransition>
                      </React.Fragment>
                    })}
                    <div className='d-flex flex-row'>
                      <div className='icon__lista__task d-flex justify-content-end'>
                          <img src={IcoList} alt=''/>
                      </div>
                      <div className='table__custom__row--btnadd d-flex flex-row align-items-center'>
                        {tareaSelec !== null && (
                          <>
                            <button onClick={handleNewSubtarea} className={`${tareaSelec === 100 ? "disabled" : ""} btn btn-outline-primary btn-sm rounded-pill px-3 fw-medium`}>
                              <i className="bi bi-plus me-1"></i>Crear una subtarea
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </subtareasAuditContext.Provider>
    </>
  )
}

export default SubtareasAudit