import { createContext, useState } from "react";

export const OkrContext = createContext();

export const OkrProvider = ({ children }) => {
    
    const [errorFetch, setErrorFetch] = useState(null)

    const fetchOkrPersonales = async (user) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewObjetivoPersonales`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user: user
            })
          });
          const data = await res.json()
          return data
        } catch (error) {
          setErrorFetch("Error: Conexión con backend.")
        }
    }
    
    const fetchOkrAreas = async (user, idArea) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewObjetivoArea`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
              idArea: parseInt(idArea),
              user: user
            })
          });
          const data = await res.json()
          return data
        } catch (error) {
          setErrorFetch("Error: Conexión con backend.")
        }
    }

    const fetchOkrByEmpresa = async (user, idEmpresa) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewObjetivoEmpresa`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            idEmpresa: parseInt(idEmpresa),
            user: user
          })
        });
        const data = await res.json()
        return data
      } catch (error) {
        setErrorFetch("Error: Conexión con backend.")
      }
  }
    
    const fetchKRPersonales = async (user) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewKeyResultsPersonal`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user: user
            })
          });
          const data = await res.json()
          return data
        } catch (error) {
          setErrorFetch("Error: Conexión con backend.")
        }
    }

    const fetchKRByObjetivo = async (idOkr) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewKeyResultsObjetive`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            idObjetivo: idOkr
          })
        });
        const data = await res.json()
        return data
      } catch (error) {
        setErrorFetch("Error: Conexión con backend.")
      }
    }

    const fetchActividades = async (idKeyResult) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewActividades`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            idKeyResult: parseInt(idKeyResult)
          })
        });
        const data = await res.json()
        return data
      } catch (error) {
        setErrorFetch("Error: Conexión con backend.")
      }
    }

    const fetchComentarios = async (user, idObjetivo) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/viewComentario`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json"
          },
          body: JSON.stringify({
            user: user,
            idObjetivo: parseInt(idObjetivo)
          })
        });
        const data = await res.json()
        return data
      } catch (error) {
        setErrorFetch("Error: Conexión con backend.")
      }
    }

    return (
        <OkrContext.Provider value={{ fetchComentarios, fetchActividades, fetchOkrByEmpresa, fetchOkrPersonales, fetchOkrAreas, fetchKRPersonales, fetchKRByObjetivo, errorFetch, setErrorFetch }}>
            {children}
        </OkrContext.Provider>
    )
}