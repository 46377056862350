import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import "./ListaEmpresasOkr.scss"
import IllustrationAccess from "../../assets/img/access.png"

function ListaEmpresasOkr(props) {
  
  const [empresas, setEmpresas] = useState([])
  const [loadingEmpresas, setLoadingEmpresas] = useState(true)
  const [errorEmpresas, setErrorEmpresas] = useState(null)

  const fetchEmpresas = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: props.user,
        })
      })
      const data = await res.json()
      if(data.status !== 0){
          setErrorEmpresas(data.errorDetalle)
          setLoadingEmpresas(false)
      } else {
          setEmpresas(data.objeto)
          setLoadingEmpresas(false)
      }
    } catch (error) {
        setErrorEmpresas("Error: Conexión con backend.")
        setLoadingEmpresas(false)
    }
  }

  useEffect(()=>{
    fetchEmpresas()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      {loadingEmpresas ? (
          <div className='loading__listaEmpresasOkr d-flex flex-column align-items-center justify-content-center'>
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#0d6efd"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
            <p className='fw-medium'>Loading...</p>
          </div>
      ) : (
          <>
            {errorEmpresas ? (
              <div className='listaEmpresas__main--error d-flex flex-column align-items-center justify-content-center rounded-3'>
                <img className='mb-4' src={IllustrationAccess} alt="" />
                <h2 className='text-danger fw-semibold mb-2'>Oops!</h2>
                <p className='text-center'>{errorEmpresas}</p>
                <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
              </div>
            ) : (
              <>
                {empresas.length === 0 ? (
                  <div className='listaEmpresas__main--empty d-flex flex-column align-items-center justify-content-center rounded-3'>
                    <img className='mb-4' src={IllustrationAccess} alt="" />
                    <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes empresas asignadas.</h3>
                    <p className='text-center'>Contactar al equipo de soporte para el manejo de empresas.</p>
                    <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                  </div>
                ) : (
                  <div className='listaEmpresas__main'>
                    <div className='table__custom__emp'>
                      <div className='table__custom__emp__header'>
                          <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold cell__id text-center'></div>
                          <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold cell__name'>Nombre</div>
                          <div className='table__custom__emp__cell table__custom__emp__cell--title fw-bold'>Responsable</div>
                      </div>
                      <div className='table__custom__emp__body'>
                          {empresas.map((e, i) => {
                          return <Link key={i} className='table__custom__emp__row text-decoration-none text-dark' to={`/okr/empresa/${e.id_empresa}`}>
                              <div className='table__custom__emp__cell cell__id fw-bold text-center'>{i}</div>
                              <div className='table__custom__emp__cell cell__name'>{e.nombre}</div>
                              <div className='table__custom__emp__cell'>correo@gmail.com</div>
                          </Link>
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
      )}
    </>
  )
}

export default ListaEmpresasOkr