import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from "jwt-decode"
import NotFound from '../pages/NotFound/NotFound';

function PrivateRoutesBank() {

    const location = useLocation()

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    if(USER.puesto === 1){
        return <Outlet />
    }

    const isBancario = USER.bancario === 1;
    const currentPath = location.pathname;

    if (
        (isBancario && currentPath.includes("planes-de-accion")) ||
        (!isBancario && currentPath.includes("plan-de-auditoria"))
    ) {
        return <NotFound />
    }

    return <Outlet />
}

export default PrivateRoutesBank