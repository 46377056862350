import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import { jwtDecode } from "jwt-decode"
import "../Home/Areas.scss"
import "./Proyectos.scss"
import IllustrationAccess from "../../assets/img/access.png"

import { usePlanes } from '../../context/PlanesContext'

function AreasPA() {
    const { empresa, fetchEmpresa, fetchEmpresaSinConsultora } = usePlanes()

    const { idConsult, id } = useParams()

    const [loading, setLoading] = useState(true)
    const [areas, setAreas] = useState([])
    const [error, setError] = useState(null)

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    useEffect(() => {
        const fetchAreas = async () => {
            try {
              const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  user: USER,
                  idEmpresa: id
                })
              })
              const data = await res.json()
              if(data.status !== 0){
                setError(data.codeError)
                setLoading(false)
              } else {
                setAreas(data.objeto)
                setLoading(false)
              }
            } catch (error) {
              setError("Error: Conexión con backend.")
              setLoading(false)
            }
        }

        fetchAreas()
        if(parseInt(idConsult) === 0){
          fetchEmpresaSinConsultora(USER, id)
        } else {
          fetchEmpresa(USER, idConsult, id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='areas section'>
            <div className='section__header d-flex flex-row align-items-end mb-4'>
                <i className='bi bi-bar-chart-steps me-2'></i>
                <h4 className='m-0'><Link className='breadcrumb__generico' to={'/planes-de-accion'}>Planes de acción</Link> <i className="bi bi-chevron-right"></i> {empresa}</h4>
            </div>
            {loading ? (
            <div className='loading__areas d-flex flex-column align-items-center justify-content-center'>
              <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#0d6efd"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
              />
              <p className='fw-medium'>Loading...</p>
            </div>
            ) : (
                <>
                  {error ? (
                    <div className='areas__proyectos--error d-flex flex-column align-items-center justify-content-center'>
                      <img className='mb-4' src={IllustrationAccess} alt="" />
                      <h2 className='text-blue-500 fw-semibold mb-2'>Oops!</h2>
                      <p>{error}</p>
                      <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                    </div>
                  ) : (
                    <>
                      {areas.length === 0 ? (
                        <div className='areas__proyectos--empty d-flex flex-column align-items-center justify-content-center'>
                          <img className='mb-4' src={IllustrationAccess} alt="" />
                          <h2 className='text-blue-500 fw-semibold mb-2'>No tiene áreas asignadas.</h2>
                          <p className='text-center'>Contactar al equipo de soporte para el manejo de áreas.</p>
                          <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                        </div>
                      ) : (
                        <div className='areas__list mb-4'>
                          {areas.map((e,i) => {
                              return <Link 
                                to={`/planes-de-accion/consultora/${idConsult}/empresa/${id}/area/${e.id_area}`} 
                                className="btn areas__list__area border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center" 
                                key={i}
                              >
                              <h4 className='p-0 m-0 text-start'>{e.nombre_del_Area}</h4>
                              </Link>
                          })}
                        </div>
                      )}
                    </>
                  )}
                </>
            )}
        </div>
    )
}

export default AreasPA