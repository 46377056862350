import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ProgressBar } from 'react-bootstrap'

import { keyResultContext } from '../../pages/Okr/DetalleKeyResult'
import { OkrContext } from '../../context/OkrContext'

function ModalEditKeyResult(props) {
    const { idOkr, idKeyResult } = useParams()

    const { setKrSelected, setLoading, krObj, setKrObj, USER } = useContext(keyResultContext)
    const { fetchKRByObjetivo } = useContext(OkrContext)

    const [formData, setFormData] = useState({
        tipo: "",
        titulo: "",
        responsable: "",
        prioridad: "",
        fecha: "",
        progreso: 0,
        base: "",
        resultado: "",
        medicion: ""
    })

    const [errors, setErrors] = useState({})
    const [modalErr, setModalErr] = useState(null)

    useEffect(() => {
        if(krObj){
            const obj = JSON.parse(krObj)
            setFormData({
                tipo: obj.tipo.toString(),
                titulo: obj.titulo,
                responsable: obj.mailUser,
                prioridad: obj.prioridad.toString(),
                fecha: obj.fecha_KeyResult,
                progreso: Math.round(obj.progreso)
            })
            if(obj.tipo === 1){
                setFormData((prevState) => ({
                    ...prevState,
                    base: obj.linea_base.toString(),
                    resultado: obj.resultado_esperado.toString(),
                    medicion: obj.frecuencia.toString()
                }))
            }
        }
    }, [krObj])

    const validateForm = (data) => {
        const errors = {}

        if(!data.titulo.trim()) {
            errors.titulo = "Escoja un título."
        }
        if(!data.responsable.trim()) {
            errors.responsable = "Escriba el mail."
        } else if (!/\S+@\S+\.\S+/.test(data.responsable)){
            errors.responsable = "El email no es válido."
        }
        if(!data.prioridad.trim()) {
            errors.prioridad = "Marca una opción."
        }
        if(!data.fecha.trim()){
            errors.fecha = "Escoja una fecha."
        }
        // DATA TIPO 1
        if(formData.tipo === "1"){
            if(!data.base.trim()){
                errors.base = "Escoge una línea base."
            }
            if(!data.resultado.trim()){
                errors.resultado = "Escoge un resultado esperado."
            }
            if(!data.medicion.trim()){
                errors.medicion = "Escoge una de las opciones."
            }
        }
        return errors;
    }

    const handleClose = () => {
        setErrors({})
        setFormData({
            tipo: "",
            titulo: "",
            responsable: "",
            prioridad: "",
            fecha: "",
            progreso: 0,
            base: "",
            resultado: "",
            medicion: ""
        })
        props.onHide()
        setModalErr(null)
        setKrObj(null)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleEditKr = async (e) => {
        e.preventDefault()
        const newErrors = validateForm(formData)
        setErrors(newErrors)
        if(Object.keys(newErrors).length === 0){
            if(formData.tipo === "1"){
                try {
                    const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/modKeyResults`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            idKeyResult: idKeyResult,
                            tipo: parseInt(formData.tipo),
                            user: USER,
                            titulo: formData.titulo,
                            responsable: formData.responsable,
                            prioridad: parseInt(formData.prioridad),
                            progreso: parseInt(formData.progreso),
                            lineaBase: parseInt(formData.base),
                            resultadoEsperado: parseInt(formData.resultado),
                            frecuencia: parseInt(formData.medicion),
                        })
                    })
                    const data = await res.json()
                    if (data.status !== 0) {
                        setModalErr(data.codeError)
                    } else {
                        setLoading(true)
                        setModalErr(null)
                        setKrObj(null)
                        props.onHide()
                        // BUSCAR Key Results ACTUALIZADO POR idOkr
                        fetchKRByObjetivo(idOkr)
                        .then(res => {
                            if(res.status !== 0){
                                console.log(res.errorDetalle)
                            } else {
                                const obj = (res.objeto).find((e) => e.id_kr === parseInt(idKeyResult))
                                setKrSelected((prevState) => ({
                                    ...prevState,
                                    fk_responsable: obj.fk_responsable,
                                    titulo: obj.titulo,
                                    prioridad: parseInt(obj.prioridad),
                                    progreso: parseInt(obj.progreso),
                                    linea_base: parseInt(obj.linea_base),
                                    resultado_esperado: parseInt(obj.resultado_esperado),
                                    frecuencia: parseInt(obj.frecuencia),
                                    fecha_KeyResult: obj.fecha_KeyResult,
                                    nombreUser: obj.nombreUser,
                                    mailUser: obj.mailUser,
                                }))
                            }
                        })
                        setLoading(false)
                    }
                } catch (error) {
                    setModalErr("Error: Conexión con backend.")
                }
            } else if (formData.tipo === "2"){
                try {
                    const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/modKeyResults`, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            idKeyResult: idKeyResult,
                            tipo: parseInt(formData.tipo),
                            user: USER,
                            titulo: formData.titulo,
                            responsable: formData.responsable,
                            prioridad: parseInt(formData.prioridad),
                            progreso: parseInt(formData.progreso),
                        })
                    }) 
                    const data = await res.json()
                    if (data.status !== 0) {
                        setModalErr(data.codeError)
                    } else {
                        setLoading(true)
                        setModalErr(null)
                        setKrObj(null)
                        props.onHide()
                        // BUSCAR Key Results ACTUALIZADO POR idOkr
                        fetchKRByObjetivo(idOkr)
                        .then(res => {
                            if(res.status !== 0){
                                console.log(res.errorDetalle)
                            } else {
                                const obj = (res.objeto).find((e) => e.id_kr === parseInt(idKeyResult))
                                console.log(obj)
                                setKrSelected((prevState) => ({
                                    ...prevState,
                                    fk_responsable: obj.fk_responsable,
                                    titulo: obj.titulo,
                                    prioridad: parseInt(obj.prioridad),
                                    progreso: parseInt(obj.progreso),
                                    fecha_KeyResult: obj.fecha_KeyResult,
                                    nombreUser: obj.nombreUser,
                                    mailUser: obj.mailUser,
                                }))
                            }
                        })
                        setLoading(false)
                    }
                } catch (error) {
                    setModalErr("Error: Conexión con backend.")
                }
            }
        } else {
            setModalErr("Completar los campos mencionados.")
        }
    }

    const handleDecrease = (e) => {
        e.preventDefault()
        if(formData.progreso === 0){
            setFormData({
                ...formData,
                progreso: 0
            })
        } else {
            setFormData({
                ...formData,
                progreso: formData.progreso - 10
            })
        }
    }

    const handleIncrese = (e) => {
        e.preventDefault()
        if(formData.progreso === 100){
            setFormData({
            ...formData,
            progreso: 100
            })
        } else {
            setFormData({
            ...formData,
            progreso: formData.progreso + 10
            })
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='modal__okr'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='d-flex flex-row'>
                    <h3 className='m-0'>Editar Key Result</h3>
                    <button className='btn' onClick={handleClose}><i className="bi bi-x-lg fw-bold"></i></button>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='form__okr d-flex flex-column' onSubmit={handleEditKr}>
                    {/* Título y fecha */}
                    <div className='row mb-2'>
                        <div className='col-12 col-md-7'>
                            <label className='mb-1'>Título</label>
                            <input
                                onChange={handleChange}
                                type="text" 
                                id="titulo" 
                                name="titulo" 
                                autoFocus
                                className="form-control form-control-sm col-12"
                                value={formData.titulo}
                            />
                            {errors.titulo && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.titulo}</span>}
                        </div>
                        <div className='col-12 col-md-5'>
                            <label className='mb-1'>Fecha</label>
                            <input
                                onChange={handleChange}
                                type="date" 
                                id="fecha" 
                                name="fecha" 
                                className="form-control form-control-sm"
                                value={formData.fecha}
                            />
                            {errors.fecha && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fecha}</span>}
                        </div>
                    </div>
                    {/* Responsable y prioridad */}
                    <div className='row mb-2'>
                        <div className='col-12 col-md-7'>
                            <label className='mb-1'>Correo del responsable</label>
                            <input
                                onChange={handleChange}
                                type="email" 
                                id="responsable" 
                                name="responsable" 
                                placeholder="usuario@correo.com.ar" 
                                className="form-control form-control-sm col-12"
                                value={formData.responsable}
                                disabled={USER.puesto === 5 ? true : false}
                            />
                            {errors.responsable && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.responsable}</span>}
                        </div>
                        <div className='col-12 col-md-5'>
                            <label className='mb-1'>Prioridad</label>
                            <div className='d-flex flex-row'>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="1"
                                        checked={formData.prioridad === "1"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">Baja</label>
                                </div>
                                <div className="form-check me-3">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="2" 
                                        checked={formData.prioridad === "2"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">Media</label>
                                </div>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="radio" 
                                        name="prioridad" 
                                        value="3" 
                                        checked={formData.prioridad === "3"}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">Alta</label>
                                </div>
                            </div>
                            {errors.prioridad && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.prioridad}</span>}
                        </div>
                    </div>
                    {/* Progreso */}
                    <div className='row mb-4'>
                        <div className='col-12 col-md-6'>
                            <label className='mb-1'>Progreso</label>
                            <div className="d-flex flex-row align-items-center justify-content-between">
                                <button className='form__okr__btnProgress btn btn-primary rounded-circle p-0 d-flex align-items-center justify-content-center' onClick={handleDecrease}><i className="bi bi-dash"></i></button>
                                <ProgressBar className='form__okr__progressBar' now={Math.round(formData.progreso)} label={`${Math.round(formData.progreso)}%`} max={100}/>
                                <button className='form__okr__btnProgress btn btn-primary rounded-circle p-0 d-flex align-items-center justify-content-center' onClick={handleIncrese}><i className="bi bi-plus"></i></button>
                            </div>
                            {errors.progreso && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.progreso}</span>}
                        </div>
                    </div>
                    {formData.tipo === "1" && (
                        <>
                            {/* Línea base y resultado esperado */}
                            <div className='row mb-2'>
                                <div className='col-12 col-md-6'>
                                    <label className='mb-1'>Línea base</label>
                                    <input
                                        onChange={handleChange}
                                        type="number" 
                                        id="base" 
                                        name="base" 
                                        className="input--arrows form-control form-control-sm col-12"
                                        value={formData.base}
                                        disabled
                                    />
                                    {errors.base && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.base}</span>}
                                </div>
                                <div className='col-12 col-md-6'>
                                    <label className='mb-1'>Resultado esperado</label>
                                    <input
                                        onChange={handleChange}
                                        type="number" 
                                        id="resultado" 
                                        name="resultado" 
                                        className="input--arrows form-control form-control-sm col-12"
                                        value={formData.resultado}
                                        disabled
                                    />
                                    {errors.resultado && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.resultado}</span>}
                                </div>
                                
                            </div>
                            {/* Frecuencia de medición */}
                            <div className='row mb-4'>
                                <div className='col-12 col-md-6'>
                                    <label className='mb-1'>Frecuencia de medición</label>
                                    <select className="form-select form-select-sm" id="medicion" name="medicion" onChange={handleChange} value={formData.medicion}>
                                        <option value="">Elija una opción</option>
                                        <option value="1">Mensual</option>
                                        <option value="2">Bimestral</option>
                                        <option value="3">Trimestral</option>
                                    </select>
                                    {errors.medicion && <span className='form__okr__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.medicion}</span>}
                                </div>
                            </div>
                        </>
                    )}
                    {modalErr !== null && <span className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{modalErr}</span>}
                    <button type="submit" className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-center'>
                        Modificar Key Result
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalEditKeyResult