import React, { useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode"
import { Link } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../../assets/img/access.png"
import "../../pages/Home/Home.scss"

function ListaConsultoras() {
    const [loadingConsultoras, setLoadingConsultoras] = useState(true)
    const [loadingEmpresas, setLoadingEmpresas] = useState(false)

    const [consultoras, setConsultoras] = useState([])
    const [errorConsultoras, setErrorConsultoras] = useState(null)

    const [empresas, setEmpresas] = useState([])
    const [errorEmpresas, setErrorEmpresas] = useState(null)

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const fetchConsultoras = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/consultorasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setErrorConsultoras(data.codeError)
                setLoadingConsultoras(false)
            } else {
                setConsultoras(data.objeto)
                setLoadingConsultoras(false)
            }
        } catch (error) {
            setErrorConsultoras("Error: Conexión con backend.")
            setLoadingConsultoras(false)
        }
    }

    useEffect(() => {
        fetchConsultoras()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleEmpresasById = async (id) => {
        setLoadingEmpresas(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    idConsultora: id
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setErrorEmpresas(data.codeError)
                setLoadingEmpresas(false)
            } else {
                setEmpresas(data.objeto)
                setLoadingEmpresas(false)
            }
        } catch (error) {
            setErrorEmpresas("Error: Conexión con backend.")
            setLoadingEmpresas(false)
        }
    }

    const handleEmpresasSinConsultora = async () => {
        setLoadingEmpresas(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/sinEmpresasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setErrorEmpresas(data.codeError)
                setLoadingEmpresas(false)
            } else {
                setEmpresas(data.objeto)
                setLoadingEmpresas(false)
            }
        } catch (error) {
            setErrorEmpresas("Error: Conexión con backend.")
            setLoadingEmpresas(false)
        }
    }

    return (
        <div className='home__dashboards'>
            {loadingConsultoras ? (
                <div className='loading__home d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#0d6efd"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='fw-medium'>Loading...</p>
                </div>
            ) : (
                <>
                    {errorConsultoras ? (
                        <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
                            <img className='img__error mb-4' src={IllustrationAccess} alt="" />
                            <h2 className='text-danger fw-semibold mb-2'>Oops!</h2>
                            <p className='text-center'>{errorConsultoras}</p>
                            <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                        </div>
                    ) : (
                        <>
                            {consultoras.length === 0 ? (
                                <div className='h-100 d-flex flex-column align-items-center justify-content-center rounded-3'>
                                    <img className='img__error mb-4' src={IllustrationAccess} alt="" />
                                    <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tiene consultoras asignadas.</h3>
                                    <p className='text-center'>Contactar al equipo de soporte para el manejo de consultoras.</p>
                                    <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                                </div>
                            ) : (
                                <div className='home__consultoras mb-4'>
                                    {consultoras.map((e,i) => {
                                        return <div key={i} onClick={()=> handleEmpresasById(e.id_consultora)} className='home__consultoras__consultora border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center'>
                                            <h4 className='p-0 m-0 text-start'>{e.nombre}</h4>
                                        </div>
                                    })}
                                    <div onClick={handleEmpresasSinConsultora} className='home__consultoras__consultora border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center'>
                                        <h4 className='p-0 m-0 text-start'>Sin consultoras asociadas</h4>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {/* LISTADO DE EMPRESAS */}
            {loadingEmpresas ? (
                <div className='loading__home d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#0d6efd"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='fw-medium'>Loading...</p>
                </div>
            ) : (
                <>
                    {errorEmpresas ? (
                        <div className='no__access d-flex flex-column align-items-center justify-content-center'>
                            <img className='mb-4' src={IllustrationAccess} alt="" />
                            <h2 className='text-blue-500 fw-semibold mb-2'>Oops!</h2>
                            <p>{errorEmpresas}</p>
                        </div>
                    ) : (
                        <>
                            {empresas.length === 0 ? (
                                <div className='invisible'></div>
                            ) : (
                                <div className='table__custom'>
                                    <div className='table__custom__header'>
                                        <div className='table__custom__cell table__custom__cell--title fw-bold cell__id text-center'></div>
                                        <div className='table__custom__cell table__custom__cell--title fw-bold cell__name'>Nombre</div>
                                        <div className='table__custom__cell table__custom__cell--title fw-bold'>Responsable</div>
                                    </div>
                                    <div className='table__custom__body'>
                                        {empresas.map((e, i) => {
                                        return <Link key={i} className='table__custom__row text-decoration-none text-dark' to={`/home/empresa/${e.id_empresa}`}>
                                            <div className='table__custom__cell cell__id fw-bold text-center'>{i}</div>
                                            <div className='table__custom__cell cell__name'>{e.nombre}</div>
                                            <div className='table__custom__cell'>correo@gmail.com</div>
                                        </Link>
                                        })}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default ListaConsultoras