import React, { createContext, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'

const PlanesContext = createContext()

export function PlanesProvider({children}) {
    const [empresa, setEmpresa] =  useState(null)
    const [area, setArea] = useState(null)
    const [errorFetch, setErrorFetch] = useState(null)

    const location = useLocation()

    const fetchEmpresa = async (user, idConsultora, idEmpresa) => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/empresasView`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user,
                idConsultora: idConsultora
            })
          })
          const data = await res.json()
          const arr = data.objeto
          const obj = arr.find(e => e.id_empresa === parseInt(idEmpresa))
          setEmpresa(obj.nombre)
        } catch (error) {
          setErrorFetch("Error: Conexión con backend.")
        }
    }

    const fetchEmpresaSinConsultora = async (user, idEmpresa) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/sinEmpresasView`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user,
            })
        })
        const data = await res.json()
        const arr = data.objeto
        const obj = arr.find(e => e.id_empresa === parseInt(idEmpresa))
        setEmpresa(obj.nombre)
      } catch (error) {
        setErrorFetch("Error: Conexión con backend.")
      }
    }

    const fetchArea = async (user, idEmpresa, idArea) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  user: user,
                  idEmpresa: idEmpresa
                })
              })
              const data = await res.json()
              const arr = data.objeto
              if(user.puesto <= 3){
                const obj = arr.find(e => e.id_area === parseInt(idArea))
                setArea(obj.nombre_del_Area)
              } else if (user.puesto >= 4 && location.pathname.includes("okr")) {
                const obj = arr.find(e => e.id_area === parseInt(idArea))
                setArea(obj.nombre_del_Area)
              } else {
                const obj = arr.find(e => e.id_area === user.idArea)
                setArea(obj.nombre_del_Area)
              }
        } catch (error) {
            setErrorFetch("Error: Conexión con backend.")
        }
    }

    return (
        <PlanesContext.Provider value={{ empresa, fetchEmpresa, fetchEmpresaSinConsultora, area, fetchArea, errorFetch }}>
            {children}
        </PlanesContext.Provider>
    )
}

export function usePlanes() {
    return useContext(PlanesContext)
}