import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
// import { useLocation, Link, useParams } from "react-router-dom"
import "./Ciclos.scss"
import { Accordion } from 'react-bootstrap';
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../../assets/img/access.png"
import TareasAudit from '../../components/TareasAudit';
import ModalNewCiclo from '../../components/Modales/PlanesAuditoria/ModalNewCiclo';

import { usePlanes } from '../../context/PlanesContext';

export const cicloContext = React.createContext()

function Ciclos() {
    const { empresa, fetchEmpresa, fetchEmpresaSinConsultora, area, fetchArea } = usePlanes()
    
    const { idConsult, id, idArea, yearDefault, idCiclo } = useParams()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [loadingTar, setLoadingTar] = useState(false)
    const [errorTar, setErrorTar] = useState(null)
    const [tareasByCiclo, setTareasByCiclo] = useState(null)
    const [idCicloSelec, setIdCicloSelec] = useState(null)
    const [yearSelec, setYearSelec] = useState(null)
    const [titleCiclo, setTitleCiclo] = useState(null)
    const [descripcionCiclo, setDescripcionCiclo] = useState(null)
    const [modalCiclo, setModalCiclo] = useState(false)

    const [tareasRealporCiclo, setTareasRealporCiclo] = useState(null)
    const [tareasNorealporCiclo, setTareasNorealporCiclo] = useState(null)

    const [expandedRow, setExpandedRow] = useState(null);

    const [ciclosClose, setCiclosClose] = useState(false)

    const [ciclos, setCiclos] = useState([])

    const [areas, setAreas] = useState([]);
    const [errorAreas, setErrorAreas] = useState(null)

    //subtareas
    const [subtareas, setSubtareas] = useState([])

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    const fetchAreas = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    idEmpresa: id
                })
            })
            const data = await res.json()
            if(data.status !== 0){
                setErrorAreas(data.errorDetalle)
            } else {
                setAreas(data.objeto)
            }
        } catch (error) {
            setErrorAreas("Error: Conexión con backend.")
        }
    }

    useEffect(() => {
        const firstFetch = () => {
            fetchCiclos(yearDefault)
            .then(res => {
                if(res.error !== 0){
                    setLoading(false)
                    setError(res.errorDetalle)
                } else {
                    const obj = res.objeto.find((e) => e.id_cicloPA === parseInt(idCiclo))
                    if(obj === undefined){
                        setCiclos(res.objeto)
                        setLoading(false)
                    } else {
                        setTitleCiclo(obj.nombre)
                        setDescripcionCiclo(obj.detalles)
                        setYearSelec(yearDefault)
                        setIdCicloSelec(parseInt(idCiclo))
                        setCiclos(res.objeto)
                        setLoading(false)
                    }
                    
                }
            })

            if(idCiclo){
                fetchTareasById(idCiclo)
                .then(res => {
                    if(res.error !== 0){
                        setLoadingTar(false)
                        setErrorTar(res.errorDetalle)
                    } else {
                        setLoadingTar(false)
                        setTareasByCiclo(res.objeto)
                    }
                })
                fetchMetrica()
                .then(res => {
                    if(res.error !== 0){
                        console.log(res.errorDetalle)
                    } else {
                        let tareasNorealizadas = 0;
                        const arr = res.objeto
                        const selec = arr.find(e => e.id_cicloPA === parseInt(idCiclo))
                        if(selec === undefined) {
                            setTareasRealporCiclo(0)
                            setTareasNorealporCiclo(0)
                        } else {
                            tareasNorealizadas = selec.tareas_totales - selec.tareas_realizadas
                            setTareasNorealporCiclo(tareasNorealizadas)
                            setTareasRealporCiclo(selec.tareas_realizadas)
                        } 
                    }
                })
            }
            
        }

        firstFetch()
        
        fetchAreas()
        
        if (USER.puesto < 3) {
            if(parseInt(idConsult) === 0){
                fetchEmpresaSinConsultora(USER, id)
            } else {
                fetchEmpresa(USER, idConsult, id)
            }
        }
        fetchArea(USER, id, idArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const fetchMetrica = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/auditoria/metricas`, {
                method: "GET"
            });
            const data = await res.json();
            return data 
        } catch (error) {
            console.log("Error: Conexión con backend.")
        }
    }

    const fetchCiclos = async (y) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/auditoria/viewCiclos`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    idArea: parseInt(idArea),
                    user: USER,
                    fecha: parseInt(y)
                })
            });
            const data = await res.json()
            return data
        } catch (error) {
            setError("Error: Conexión con backend.")
        }
    }

    const fetchTareasById = async(id) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/auditoria/viewTask`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: JSON.stringify({
                user: USER,
                idCiclo: id
              })
            })
            const data = await res.json()
            return data
        } catch (error) {
            setErrorTar("Error: Conexión con backend.")
        }
    }

    // INCLUYE FETCH METRICA
    const handleTareaById = async (id, year, titleCiclo, detalles) => {
        setLoadingTar(true)
        fetchTareasById(id)
        .then(res => {
            if(res.error !== 0){
                setLoadingTar(false)
                setErrorTar(res.errorDetalle)
            } else {
                setLoadingTar(false)
                setTareasByCiclo(res.objeto)
            }
        })
        fetchMetrica()
        .then(res => {
            if(res.error !== 0){
                console.log(res.errorDetalle)
            } else {
                let tareasNorealizadas = 0;
                const arr = res.objeto
                const selec = arr.find(e => e.id_cicloPA === parseInt(id))
                if(selec === undefined) {
                    setTareasRealporCiclo(0)
                    setTareasNorealporCiclo(0)
                } else {
                    tareasNorealizadas = selec.tareas_totales - selec.tareas_realizadas
                    setTareasNorealporCiclo(tareasNorealizadas)
                    setTareasRealporCiclo(selec.tareas_realizadas)
                } 
            }
        })
        setIdCicloSelec(id)
        setYearSelec(year)
        setTitleCiclo(titleCiclo)
        setDescripcionCiclo(detalles)
        setSubtareas([])
        setExpandedRow(null)
    }

    const handleFormCiclo = (e) => {
        e.preventDefault()
        setModalCiclo(true)
    }

    const handleCloseCiclos = () => {
        setCiclosClose(!ciclosClose)
    }

    const ruta = USER.puesto >= 3 ? "" : `/consultora/${idConsult}/empresa/${id}`

    return (
        <cicloContext.Provider value={{ fetchMetrica, idArea, USER, areas, errorAreas, subtareas, setSubtareas, loadingTar, setLoadingTar, errorTar, setErrorTar, ciclos, setCiclos, fetchCiclos, fetchTareasById, tareasByCiclo, setTareasByCiclo, yearSelec, setYearSelec, idCicloSelec, setIdCicloSelec, titleCiclo, descripcionCiclo, setTitleCiclo, setDescripcionCiclo, expandedRow, setExpandedRow, tareasRealporCiclo, tareasNorealporCiclo, setTareasRealporCiclo, setTareasNorealporCiclo, handleCloseCiclos, ciclosClose}}>
            <ModalNewCiclo show={modalCiclo} onHide={()=>setModalCiclo(false)}/>
            <div className='ciclos section'>
                <div className='section__header d-flex flex-row align-items-end mb-4'>
                    <i className='bi bi-bar-chart-steps me-2'></i>
                    <h4 className='m-0'><Link to={'/plan-de-auditoria'} className='breadcrumb__generico'>Plan de auditoría</Link> {USER.puesto < 3 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/plan-de-auditoria${ruta}`}>{`${empresa}`}</Link></>} {USER.puesto <= 3 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/plan-de-auditoria${ruta}/area/${idArea}`}>{area}</Link></>} {USER.puesto >= 4 && <><i className="bi bi-chevron-right"></i> {area}</>} <i className="bi bi-chevron-right"></i> ciclos</h4>
                </div>
                {loading ? (
                    <div className='loading__ciclos d-flex flex-column align-items-center justify-content-center'>
                        <Oval
                            visible={true}
                            height="80"
                            width="80"
                            color="#0d6efd"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                        <p className='fw-medium'>Loading...</p>
                    </div>
                ): (
                    <>
                        {error ? (
                            <div className='ciclos__error d-flex flex-column align-items-center justify-content-center'>
                                <img className='mb-4' src={IllustrationAccess} alt="" />
                                <h2>Oops!</h2>
                                <p>{error}</p>
                            </div>
                        ) : (
                            <>
                                {(yearDefault === undefined) ? (
                                    <div className='ciclos__error d-flex flex-column align-items-center justify-content-center'>
                                        <img className='mb-4' src={IllustrationAccess} alt="" />
                                        <h2 className='mb-4'>Ha ocurrido un error :(</h2>
                                        <Link className='btn btn-primary shadow-sm fw-medium rounded-pill px-4' to={"/planes-de-accion"}>Regresar a Planes de acción</Link>   
                                    </div>
                                ) : (
                                    <div className='ciclos__main d-flex flex-column flex-md-row'>
                                        <div className={`${ciclosClose ? "invisible ciclos__main__menu--close" : "d-flex"} ciclos__main__menu mb-4 mb-md-0 flex-column align-items-start justify-content-between`} >
                                            <div className='container__accordion'>
                                                <Accordion className='mb-2 mb-md-0' defaultActiveKey="0">
                                                    <Accordion.Item eventKey='0'>
                                                        <Accordion.Header>{yearDefault}</Accordion.Header>
                                                        <Accordion.Body className='d-flex flex-column align-items-start'>
                                                            {ciclos.map((c,i) => {
                                                                return <button
                                                                    id={c.id_cicloPA}
                                                                    key={i} 
                                                                    className={`btn ${c.id_cicloPA === idCicloSelec ? "selected " : "unselected "}btn__accordion d-flex align-items-center`}
                                                                    onClick={() => handleTareaById(c.id_cicloPA, parseInt(yearDefault), c.nombre, c.detalles)}
                                                                    >
                                                                        <i className="bi bi-chevron-right me-2 active"></i><span>{c.nombre}</span>
                                                                </button>
                                                            })}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                            <button 
                                                className='ciclos__main__menu__btn btn btn-primary rounded-pill shadow-sm fw-medium'
                                                onClick={handleFormCiclo}
                                                >Agregar ciclo
                                            </button>
                                        </div>
                                        <TareasAudit />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </cicloContext.Provider>
    )
}

export default Ciclos