import React, { useState, useEffect, useContext } from 'react'
import { Modal } from 'react-bootstrap';
import { jwtDecode } from "jwt-decode"
import "./ModalCiclo.scss"
import { cicloContext } from '../../../pages/PlanesAuditoria/Ciclos';

function ModalNewCiclo(props) {
  const { idArea, ciclos, setCiclos, fetchCiclos, yearSelec } = useContext(cicloContext)
  const auth = localStorage.getItem("token")
  const jwtParse = jwtDecode(auth)
  const USER = jwtParse.apirest.objeto

  const [formCiclo, setFormCiclo] = useState({
      nombre: "",
      detalles: "",
      fechaInicio: "",
      fechaFinal: "",
  })
  const [errors, setErrors] = useState({})
  const [modalErr, setModalErr] = useState(null)

  useEffect(() => {
  
  },[ciclos])

  const handleClose = () => {
    setErrors({})
    setFormCiclo({
        nombre: "",
        detalles: "",
        fechaInicio: "",
        fechaFinal: "",
    })
    setModalErr(null)
    props.onHide()
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormCiclo({
    ...formCiclo,
    [name]: value,
    })
  }

  const validateForm = (data) => {
    const errors = {}
    if(!data.nombre.trim()) {
        errors.nombre = "Escoja un nombre"
    }
    if(!data.detalles.trim()) {
        errors.detalles = "Escoja una descripción"
    }
    if(!data.fechaInicio.trim()) {
        errors.fechaInicio = "Escoja una fecha de inicio."
    }
    if(!data.fechaFinal.trim()) {
        errors.fechaFinal = "Escoja una fecha de término."
    }
    return errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = validateForm(formCiclo)
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0){
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/planAccion/addProyect`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    nombre: formCiclo.nombre,
                    detalles: formCiclo.detalles,
                    fechaInicial: formCiclo.fechaInicio,
                    fechaFinal: formCiclo.fechaFinal,
                    idArea: parseInt(idArea)
                })
            })
            const data = await res.json()
            if(data.error !== 0){
                setModalErr(data.errorDetalle)
            } else {
                fetchCiclos(yearSelec).then(res => setCiclos(res.objeto))
                setFormCiclo({
                    nombre: "",
                    detalles: "",
                    fechaInicio: "",
                    fechaFinal: "",
                })
                setModalErr(null)
                props.onHide()
            }
        } catch (error) {
            setModalErr("Error: Conexión con backend.")
        }
    } else {
        setModalErr("Completar los campos mencionados.")
    }
  }

  return (
    <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className='modal__ciclos'
    >
      <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className='d-flex flex-row'>
              <h3 className='m-0'>Crear Ciclo</h3>
              <button className='btn' onClick={handleClose}><i className="bi bi-x-lg fw-bold"></i></button>
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <form className='form__ciclos d-flex flex-column' onSubmit={handleSubmit}>
              <div className='mb-2 col-12'>
                  <label className='mb-1'>Nombre del Ciclo</label>
                  <input 
                      onChange={handleChange}
                      type="text" 
                      id="nombre"
                      name='nombre'
                      autoFocus
                      className="form-control form-control-sm"
                      value={formCiclo.nombre}
                  />
                  {errors.nombre && <span className='form__ciclos__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.nombre}</span>}
              </div>
              <div className='mb-2 col-12'>
                  <label className='mb-1'>Descripción del Ciclo</label>
                  <input 
                      onChange={handleChange}
                      type="text" 
                      id="detalles"
                      name='detalles'
                      className="form-control form-control-sm"
                      value={formCiclo.detalles}
                  />
                  {errors.detalles && <span className='form__ciclos__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.detalles}</span>}
              </div>
              {/* Agregar fecha inicio y final */}
              <div className='row mb-4'>
                  <div className='col-6'>
                      <label className='mb-1'>Fecha de inicio</label>
                      <input
                          onChange={handleChange}
                          type="date" 
                          id="fechaInicio" 
                          name="fechaInicio" 
                          className="form-control form-control-sm"
                          value={formCiclo.fechaInicio}
                      />
                      {errors.fechaInicio && <span className='form__ciclos__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fechaInicio}</span>}
                  </div>
                  <div className='col-6'>
                      <label className='mb-1'>Fecha de término</label>
                      <input
                          onChange={handleChange}
                          type="date" 
                          id="fechaFinal" 
                          name="fechaFinal" 
                          className="form-control form-control-sm"
                          value={formCiclo.fechaFinal}
                      />
                      {errors.fechaFinal && <span className='form__ciclos__error d-flex flex-row align-items-center px-1 my-1'><i className="bi bi-exclamation-circle me-1"></i>{errors.fechaFinal}</span>}
                  </div>
              </div>
              {modalErr !== null && <span className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{modalErr}</span>}
              <button type='submit' className='px-4 btn btn-primary rounded-pill shadow-sm fw-medium align-self-center'>
                  Agregar ciclo
              </button>
          </form>
      </Modal.Body>
    </Modal>
  )
}

export default ModalNewCiclo