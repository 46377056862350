import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import "./DetalleKeyResult.scss"
import { ProgressBar, Modal } from 'react-bootstrap';
import Avatar from "../../assets/img/avatar-3.jpg"
import ModalEditKeyResult from '../../components/Modales/ModalEditKeyResult'
import ModalEditActividad from '../../components/Modales/ModalEditActividad'

import { OkrContext } from '../../context/OkrContext'
import { usePlanes } from '../../context/PlanesContext'

export const keyResultContext = React.createContext()

function DetalleKeyResult() {
    const { empresa, fetchEmpresa, area, fetchArea, fetchEmpresaSinConsultora } = usePlanes()
    const { idConsult, id, idArea, idOkr, idKeyResult } = useParams()

    const navigate = useNavigate()
    const location = useLocation()

    // const { fetchKRByObjetivo, fetchKRPersonales, fetchActividades } = useContext(OkrContext)
    const { fetchKRByObjetivo, fetchActividades } = useContext(OkrContext)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const [krSelected, setKrSelected] = useState(null)

    // Actividades
    const [idActividad, setIdActividad] = useState(null)
    const [modalEditActividad, setModalEditActividad] = useState(false)
    const [objActividad, setObjActividad] = useState(null)

    const [modalDelActividad, setModalDelActividad] = useState(false)
    const [errorDelActividad, setErrorDelActividad] = useState(null)

    const [krObj, setKrObj] = useState(null)
    const [modalEditKeyResult, setModalEditKeyResult] = useState(false)

    const [modalDelKeyResult, setModalDelKeyResult] = useState(false)
    const [errorDel, setErrorDel] = useState(null)

    const [ingresoNuevo, setIngresoNuevo] = useState("")
    const [errorIngresoNuevo, setErrorIngresoNuevo] = useState(null)
    const [fecha, setFecha] = useState("")

    const [arrIngresos, setArrIngresos] = useState([])
    const [loadingIngresos, setLoadingIngresos] = useState(true)
    const [errorIngresos, setErrorIngresos] = useState(null)

    const [newHito, setNewHito] = useState("")
    const [errorHito, setErrorHito] = useState(null)
    const [fechaHito, setFechaHito] = useState("")

    const [errorChangeHito, setErrorChangeHito] = useState(null)

    const auth = localStorage.getItem("token")
    const jwtParse = jwtDecode(auth)
    const USER = jwtParse.apirest.objeto

    useEffect(() => {
        if(USER.puesto < 3){
            if(parseInt(idConsult) === 0){
                fetchEmpresaSinConsultora(USER, id)
            } else {
                fetchEmpresa(USER, idConsult, id)
            }
        }

        fetchKRByObjetivo(idOkr)
        .then(res => {
            if(res.status !== 0){
                setLoading(false)
                setError(res.errorDetalle)
            } else {
                setLoading(false)
                const obj = res.objeto.find(e => e.id_kr === parseInt(idKeyResult))
                setKrSelected(obj)
            }
        })
        fetchActividades(idKeyResult)
        .then(res => {
            if(res.status !== 0){
                setLoadingIngresos(false)
                setErrorIngresos(res.errorDetalle)
            } else {
                setLoadingIngresos(false)
                setArrIngresos(res.objeto)
            }
        })

        fetchArea(USER, id, idArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleEditKeyResult = (id) => {
        setKrObj(JSON.stringify(krSelected))
        setModalEditKeyResult(true)
    }

    const handleModalDelKeyResult = () => {
        setModalDelKeyResult(true)
    }

    const handleCloseEliminar = () => {
        setModalDelKeyResult(false)
        setErrorDel(null)
    }

    const deleteKeyResult = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/deleteKeyResults`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    idKeyResult: idKeyResult, 
                })
            });
            const data = await res.json()
            if(data.status !== 0){
                setErrorDel(data.codeError)
            } else {
                setModalDelKeyResult(false)
                if((location.pathname.includes("consultora") && idArea === undefined)){
                    navigate(`/okr${ruta}/empresariales/${idOkr}`)
                } else if (location.pathname.includes("consultora") && location.pathname.includes("area")){
                    navigate(`/okr${ruta}/area/${idArea}/okr/${idOkr}`)
                } else if (location.pathname.includes("area") && location.pathname.includes("empresa") && idConsult === undefined){
                    navigate(`/okr${ruta}/area/${idArea}/okr/${idOkr}`)
                } else if (location.pathname.includes("personales")){
                    navigate(`/okr${ruta}/personales/${idOkr}`)
                } else if (location.pathname.includes("empresariales") && idConsult === undefined){
                    navigate(`/okr${ruta}/empresariales/${idOkr}`)
                } else if (location.pathname.includes("area") && USER.puesto >= 3){
                    navigate(`/okr/area/${idArea}/okr/${idOkr}`)
                }
            }
        } catch (error) {
            setErrorDel("Error: Conexión con backend.")
        }
    }

    const handleAddNewIngreso = async () => {
        if(ingresoNuevo.trim() !== "" && fecha.trim() !== "") {
            const obj = {
                krId: parseInt(idKeyResult),
                user: USER,
                dato: parseInt(ingresoNuevo),
                fecha: fecha,
            }
            setLoadingIngresos(true)
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newActividades`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                const data = await res.json()
                if(data.status !== 0){
                    setLoadingIngresos(false)
                    setErrorIngresoNuevo(data.codeError)
                } else {
                    setLoadingIngresos(false)
                    setFecha("")
                    setIngresoNuevo("")
                    setErrorIngresoNuevo(null)
                    fetchActividades(idKeyResult).then(res => setArrIngresos(res.objeto))
                }
            } catch (error) {
                setLoadingIngresos(false)
                setErrorIngresoNuevo("Error: Conexión con backend.")
            }
        } else {
            setErrorIngresoNuevo("Completa ambos campos.")
        }
    }

    const handleCheckboxChange = async (id, event) => {
        setLoadingIngresos(true)
        const newValue = event.target.checked
        const obj = {
            idActividad: id,
            user: USER,
            dato: newValue
        }
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/modActividades`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            const data = await res.json() 
            if(data.status !== 0){
                setLoadingIngresos(false)
                setErrorChangeHito(data.codeError)
            } else {
                setLoadingIngresos(false)
                fetchActividades(idKeyResult).then(res => setArrIngresos(res.objeto))
            }
        } catch (error) {
            setLoadingIngresos(false)
            setErrorChangeHito("Error: Conexión con backend.")
        }
    };

    const handleAddNewHito = async () => {
        if(newHito.trim() !== "" && fechaHito.trim() !== ""){
            const obj = {
                krId: parseInt(idKeyResult),
                user: USER,
                detalles: newHito,
                dato: 0,
                fecha: fechaHito
            }
            setLoadingIngresos(true)
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/newActividades`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                const data = await res.json()
                if(data.status !== 0){
                    setLoadingIngresos(false)
                    setErrorHito(data.codeError)
                } else {
                    setLoadingIngresos(false)
                    setFechaHito("")
                    setNewHito("")
                    setErrorHito(null)
                    fetchActividades(idKeyResult).then(res => setArrIngresos(res.objeto))
                }
            } catch (error) {
                setLoadingIngresos(false)
                setErrorHito("Error: Conexión con backend.")
            }
        } else {
            setErrorHito("Completa ambos campos.")
        }
    }

    const handleEditarActividad = (id) => {
        setIdActividad(id)
        const obj = arrIngresos.find((e) => e.id_Actividades_Kr === parseInt(id))
        setObjActividad(JSON.stringify(obj))
        setModalEditActividad(true)
    }

    const handleCloseDelActividad = () => {
        setModalDelActividad(false)
        setIdActividad(null)
        setErrorDelActividad(null)
    }

    const handleDeleteActividad = (id) => {
        setModalDelActividad(true)
        setIdActividad(id)
    }

    const deleteActividad = async () => {
        setLoadingIngresos(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/okr/deleteActividades`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: USER,
                    idActividad: idActividad  
                })
            });
            const data = await res.json()
            if(data.status !== 0){
                setLoadingIngresos(false)
                setErrorDelActividad(data.codeError)
            } else {
                setModalDelActividad(false)
                setIdActividad(null)
                setErrorDelActividad(null)
                // FETCH ACTIVIDADES
                fetchActividades(idKeyResult).then(res => setArrIngresos(res.objeto))
                setLoadingIngresos(false)
            }
        } catch (error) {
            setLoadingIngresos(false)
            setErrorDelActividad("Error: Conexión con backend.")
        }
    }

    // const ruta = USER.puesto >= 3 ? "" : `/consultora/${idConsult}/empresa/${id}`
    let ruta;
    if(USER.puesto === 1){
        ruta = `/consultora/${idConsult}/empresa/${id}`
    }
    if(USER.puesto === 2){
        ruta = `/empresa/${id}`
    }
    if(USER.puesto >= 3){
        ruta = ""
    }

    return (
        <>
            <keyResultContext.Provider value={{setKrSelected, setLoading, krObj, setKrObj, idActividad, objActividad, setObjActividad, setLoadingIngresos, setArrIngresos, USER}}>
                {/* Modal ELIMINAR ACTIVIDAD ACTIVIDAD ACTIVIDAD */}
                <Modal className='modal__delete' show={modalDelActividad} onHide={() => setModalDelActividad(false)} backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title className='d-flex flex-row'>
                            <h3>Eliminar Actividad</h3>
                            <button className='btn' onClick={handleCloseDelActividad}><i className="bi bi-x-lg fw-bold"></i></button>
                        </Modal.Title> 
                    </Modal.Header>
                    <Modal.Body>¿Está seguro de eliminar esta Actividad?</Modal.Body>
                    <Modal.Footer className='d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center align-self-end'>
                            <button className='btn btn-secondary rounded-pill me-2' onClick={handleCloseDelActividad}>Cancelar</button>
                            <button className='btn btn-danger rounded-pill' onClick={deleteActividad}>Borrar</button>
                        </div>
                        {errorDelActividad && <p className='align-self-center text-danger my-2'>
                            <i className="bi bi-exclamation-circle me-1"></i>{errorDelActividad}
                        </p>}
                    </Modal.Footer>
                </Modal>
                {/* Modal ELIMINAR KEY RESULT KEY RESULT KEY RESULT */}
                <Modal className='modal__delete' show={modalDelKeyResult} onHide={() => setModalDelKeyResult(false)} backdrop="static" centered>
                    <Modal.Header>
                        <Modal.Title className='d-flex flex-row'>
                            <h3>Eliminar Key Result</h3>
                            <button className='btn' onClick={handleCloseEliminar}><i className="bi bi-x-lg fw-bold"></i></button>
                        </Modal.Title> 
                    </Modal.Header>
                    <Modal.Body>¿Está seguro de eliminar este Key Result?</Modal.Body>
                    <Modal.Footer className='d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center align-self-end'>
                            <button className='btn btn-secondary rounded-pill me-2' onClick={handleCloseEliminar}>Cancelar</button>
                            <button className='btn btn-danger rounded-pill' onClick={deleteKeyResult}>Borrar</button>
                        </div>
                        {errorDel && <p className='align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{errorDel}</p>}
                    </Modal.Footer>
                </Modal>
                {/* Modal EDITAR Key Result */}
                <ModalEditKeyResult show={modalEditKeyResult} onHide={()=>setModalEditKeyResult(false)}/>
                {/* Modal EDITAR ACTIVIDAD */}
                <ModalEditActividad show={modalEditActividad} onHide={()=>setModalEditActividad(false)}/>
                <div className='KRDetalle section'>
                    <div className='section__header d-flex flex-row align-items-end mb-4'>
                        <i className='bi bi-list-check me-2'></i>
                        {/* BREADCRUMB CONSULTORAS */}
                        {(location.pathname.includes("consultora") && idArea === undefined) && (
                            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> {USER.puesto === 1 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> <Link to={`/okr${ruta}/empresariales/${idOkr}`} className='breadcrumb__generico'>Detalle del OKR</Link> <i className="bi bi-chevron-right"></i> Key Result</h4>
                        )}
                        {/* BREADCRUMB CONSULTORAS > AREAS */}
                        {(location.pathname.includes("consultora") && location.pathname.includes("area")) && (
                            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> {USER.puesto === 1 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}/area/${idArea}`}>{area}</Link> <i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}/area/${idArea}/okr/${idOkr}`}>Detalle del OKR</Link> <i className="bi bi-chevron-right"></i> Key Result</h4>
                        )}
                        {/* BREADCRUMB EMPRESAS > AREAS */}
                        {(location.pathname.includes("area") && location.pathname.includes("empresa") && idConsult === undefined) && (
                            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> {USER.puesto === 2 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}/area/${idArea}`}>{area}</Link> <i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}/area/${idArea}/okr/${idOkr}`}>Detalle del OKR</Link> <i className="bi bi-chevron-right"></i> Key Result</h4>
                        )}
                        {/* BREADCRUMB PERSONALES */}
                        {location.pathname.includes("personales") && (
                            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> <i className="bi bi-chevron-right"></i> <Link to={`/okr/personales/${idOkr}`} className='breadcrumb__generico'>Detalle del OKR</Link> <i className="bi bi-chevron-right"></i> Key Result</h4>
                        )}
                        {/* BREADCRUMB EMPRESARIALES */}
                        {(location.pathname.includes("empresariales") && idConsult === undefined) && (
                            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> {USER.puesto === 2 && <><i className="bi bi-chevron-right"></i> <Link className='breadcrumb__generico' to={`/okr${ruta}`}>{`${empresa}`}</Link></>} <i className="bi bi-chevron-right"></i> <Link to={`/okr${ruta}/empresariales/${idOkr}`} className='breadcrumb__generico'>Detalle del OKR</Link> <i className="bi bi-chevron-right"></i> Key Result</h4>
                        )}
                        {/* BREADCRUMB AREAS */}
                        {(location.pathname.includes("area") && USER.puesto >= 3) && (
                            <h4 className='m-0'><Link to={'/okr'} className='breadcrumb__generico'>OKR</Link> <i className="bi bi-chevron-right"></i> <Link to={`/okr/area/${idArea}`} className='breadcrumb__generico'>{area}</Link> <i className="bi bi-chevron-right"></i> <Link to={`/okr/area/${idArea}/okr/${idOkr}`} className='breadcrumb__generico'>Detalle del OKR</Link> <i className="bi bi-chevron-right"></i> Key Result</h4>
                        )}
                    </div>
                    <div className='KRDetalle__main'>
                        {loading ? (
                            <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                            <Oval
                                visible={true}
                                height="80"
                                width="80"
                                color="#0d6efd"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                            <p className='fw-medium'>Loading...</p>
                            </div>
                        ) : (
                            <>
                                {error ? (
                                    <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                        <h3 className='text-danger mb-1'>Oops!</h3>
                                        <p className='m-0'>{error}</p>
                                    </div>
                                ) : (
                                    <>
                                        {krSelected && (
                                            <>
                                                <section className='KRDetalle__main__info'>
                                                    <div className='KRDetalle__main__info__title d-flex flex-row align-items-center mb-4'>
                                                        <h3 className='m-0 me-3 text-blue-500'>{krSelected.titulo}</h3>
                                                        {/* Aquí se limita que el usuario que no tenga el mismo mail que el responsable del okr no pueda ver los botones */}
                                                        {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1))  && (
                                                            <div className='d-flex flex-column flex-md-row'>
                                                                <button onClick={()=> handleEditKeyResult(idKeyResult)} className='btn__edit btn bg-success rounded-circle mb-2 mb-md-0 me-md-2 text-white'><i className="bi bi-pencil"></i></button>
                                                                <button onClick={handleModalDelKeyResult} className='btn__delete btn bg-danger rounded-circle text-white'><i className="bi bi-trash3"></i></button>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {krSelected.tipo === 1 && (
                                                        <div className='KRDetalle__main__info__medible'>
                                                            <div className='KRDetalle__main__info__medible__progreso'>
                                                                <p className='subtitulo m-0 p-0 mb-2 text-blue-500'>Progreso</p>
                                                                <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(krSelected.progreso)} label={`${Math.round(krSelected.progreso)}%`} max={100}/>
                                                            </div>
                                                            <div className='KRDetalle__main__info__medible__datos d-flex flex-row align-items-center mb-4'>
                                                                <div className='d-flex align-items-center me-4'>
                                                                    <h4 className='m-0 me-2 text-blue-500'>Línea base</h4>
                                                                    <div className='input__fake rounded-2 border border-light-subtle p-1 text-center'>{krSelected.linea_base}</div>
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <h4 className='m-0 me-2 text-blue-500'>Resultado esperado</h4>
                                                                    <div className='input__fake rounded-2 border border-light-subtle p-1 text-center'>{krSelected.resultado_esperado}</div>
                                                                </div>
                                                            </div>
                                                            <div className='KRDetalle__main__info__medible__ingresos'>
                                                                <h4 className='m-0 mb-3 text-blue-500'>Ingresos</h4>
                                                                {loadingIngresos ? (
                                                                    <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                                                        <Oval
                                                                            visible={true}
                                                                            height="80"
                                                                            width="80"
                                                                            color="#0d6efd"
                                                                            ariaLabel="oval-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClass=""
                                                                        />
                                                                        <p className='fw-medium'>Loading...</p>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {errorIngresos ? (
                                                                            <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                                                                <h3 className='text-danger mb-1'>Oops!</h3>
                                                                                <p className='m-0'>{error}</p>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {arrIngresos.length === 0 ? (
                                                                                    <div className='ingresos__main--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                                                                                        <h4 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes actividades asignados en este Key Result.</h4>
                                                                                        {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1)) && (
                                                                                            <p className='mb-0 text-center'>Para comenzar, ingresa tu primera actividad.</p>
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className='ingresos__main mb-4'>
                                                                                        {arrIngresos.map((e, i) => {
                                                                                            return <div key={i} className='each__ingreso d-flex flex-row align-items-center mb-2'>
                                                                                                <p className='m-0 me-2 text-end'>{e.fecha_Actividad.replace(/-/g, '/').split("/").reverse().join("/")}</p>
                                                                                                <div className='input__fake me-2 rounded-2 border border-light-subtle p-1 text-center'>{e.dato}</div>
                                                                                                {/* <div className='icon__ingresoOk p-0 d-flex align-items-center justify-content-center text-bg-success rounded-circle'>
                                                                                                    <i className="bi bi-check-lg"></i>
                                                                                                </div> */}
                                                                                                {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1))  && (
                                                                                                    <>
                                                                                                        <button onClick={() => handleEditarActividad(e.id_Actividades_Kr)} className='btn__EditarActividad d-flex align-items-center justify-content-center p-0 btn btn-success rounded-circle me-2'>
                                                                                                            <i className="bi bi-pencil"></i>
                                                                                                        </button>
                                                                                                        <button onClick={() => handleDeleteActividad(e.id_Actividades_Kr)} className='btn__DeleteActividad d-flex align-items-center justify-content-center p-0 btn btn-danger rounded-circle'>
                                                                                                            <i className="bi bi-trash3"></i>
                                                                                                        </button>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1))  && (
                                                                    <div className='addIngreso__container'>
                                                                        <div className='d-flex flex-row align-items-center'>
                                                                            <div className='me-2'>
                                                                                <input
                                                                                    onChange={(e) => setFecha(e.target.value)}
                                                                                    type="date" 
                                                                                    id="fecha" 
                                                                                    name="fecha" 
                                                                                    className="input__date--addIngreso form-control form-control-sm"
                                                                                    value={fecha}
                                                                                />
                                                                            </div>
                                                                            <div className='me-2'>
                                                                                <input 
                                                                                    onChange={(e) => setIngresoNuevo(e.target.value)}
                                                                                    type='number'
                                                                                    id='ingreso'
                                                                                    name='ingreso'
                                                                                    placeholder='Ingresa un valor'
                                                                                    className="input--arrows input__number--addIngreso form-control form-control-sm text-center"
                                                                                    value={ingresoNuevo}
                                                                                />
                                                                            </div>
                                                                            <button onClick={handleAddNewIngreso} className='btn__addIngreso d-flex align-items-center justify-content-center p-0 btn btn-primary rounded-circle'>
                                                                                <i className="bi bi-plus-lg"></i>
                                                                            </button>
                                                                        </div>
                                                                        {errorIngresoNuevo !== null && <span className='ingreso--error align-self-center text-danger my-2'><i className="bi bi-exclamation-circle me-1"></i>{errorIngresoNuevo}</span>}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {krSelected.tipo === 2 && (
                                                        <div className='KRDetalle__main__info__nomedible'>
                                                            <div className='KRDetalle__main__info__nomedible__progreso'>
                                                                <p className='subtitulo m-0 p-0 mb-2 text-blue-500'>Progreso</p>
                                                                <ProgressBar className='mb-4 progreso__bar progreso__bar--global' now={Math.round(krSelected.progreso)} label={`${Math.round(krSelected.progreso)}%`} max={100}/>
                                                            </div>
                                                            <div className='KRDetalle__main__info__nomedible__hitos'>
                                                                <h4 className='m-0 mb-3 text-blue-500'>Hitos</h4>
                                                                {loadingIngresos ? (
                                                                    <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                                                        <Oval
                                                                            visible={true}
                                                                            height="80"
                                                                            width="80"
                                                                            color="#0d6efd"
                                                                            ariaLabel="oval-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClass=""
                                                                        />
                                                                        <p className='fw-medium'>Loading...</p>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {errorIngresos ? (
                                                                            <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                                                                <h3 className='text-danger mb-1'>Oops!</h3>
                                                                                <p className='m-0'>{error}</p>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {arrIngresos.length === 0 ? (
                                                                                    <>
                                                                                        <div className='hitos__main--empty d-flex flex-column align-items-center justify-content-center rounded-3 p-4 mb-4'>
                                                                                            <h4 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes actividades asignados en este Key Result.</h4>
                                                                                            {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1)) && (
                                                                                                <p className='mb-0 text-center'>Para comenzar, ingresa tu primera actividad.</p>
                                                                                            )}
                                                                                        </div>
                                                                                        {/* AGREGAR NUEVO HITO */}
                                                                                        {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1))  && (
                                                                                            <div className='addHito__container'>
                                                                                                <div className='d-flex flex-row align-items-center'>
                                                                                                    <div className='me-2'>
                                                                                                        <label className='mb-1'>Ingresa una actividad</label>
                                                                                                        <input 
                                                                                                            onChange={(e) => setNewHito(e.target.value)}
                                                                                                            type='text'
                                                                                                            id='hito'
                                                                                                            name='hito'
                                                                                                            className="input__text--addHito form-control form-control-sm"
                                                                                                            value={newHito} 
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='me-2'>
                                                                                                        <label className='mb-1'>Fecha límite</label>
                                                                                                        <input
                                                                                                            onChange={(e) => setFechaHito(e.target.value)}
                                                                                                            type="date" 
                                                                                                            id="fechaHito" 
                                                                                                            name="fechaHito" 
                                                                                                            className="input__date--addHito form-control form-control-sm"
                                                                                                            value={fechaHito}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='align-self-end mb-1'>
                                                                                                        <button onClick={handleAddNewHito} className='btn__addHito d-flex align-items-center justify-content-center p-0 btn btn-primary rounded-circle'>
                                                                                                            <i className="bi bi-plus-lg"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {errorHito !== null && <span className='hito--error align-self-center text-danger my-2'>
                                                                                                    <i className="bi bi-exclamation-circle me-1"></i>{errorHito}
                                                                                                </span>}
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className='hitos__main '>
                                                                                        <div className='tableHitos__custom mb-4'>
                                                                                            <div className='tableHitos__custom__header'>
                                                                                                <div className='tableHitos__custom__cell tableHitos__custom__cell--title fw-bold cell__check'></div>
                                                                                                <div className='tableHitos__custom__cell tableHitos__custom__cell--title fw-bold cell__actividad'>Actividad</div>
                                                                                                <div className='tableHitos__custom__cell tableHitos__custom__cell--title fw-bold'>Fecha límite</div>
                                                                                                <div className='tableHitos__custom__cell tableHitos__custom__cell--title fw-bold'></div>
                                                                                            </div>
                                                                                            <div className='tableHitos__custom__body'>
                                                                                                {arrIngresos.map((e, i) => {
                                                                                                    return <div key={i} className="tableHitos__custom__row each__ingreso d-flex flex-row align-items-center">
                                                                                                        <div className='tableHitos__custom__cell cell__check d-flex flex-row align-items-center justify-content-center'>
                                                                                                            <input 
                                                                                                                onChange={(event) => handleCheckboxChange(e.id_Actividades_Kr, event)}
                                                                                                                className="form-check-input m-0" 
                                                                                                                type="checkbox"
                                                                                                                checked={e.dato}
                                                                                                                disabled={e.dato === 1 ? true : false}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className={`${e.dato === 1 && "text-decoration-line-through"} tableHitos__custom__cell cell__actividad`}>{e.detalle}</div>
                                                                                                        <div className='tableHitos__custom__cell'>{e.fecha_Actividad.replace(/-/g, '/').split("/").reverse().join("/")}</div>
                                                                                                        <div className='tableHitos__custom__cell d-flex flex-row'>
                                                                                                            {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1))  && (
                                                                                                                <>
                                                                                                                    {e.dato === 1 ? (
                                                                                                                        <>
                                                                                                                            <button disabled className='btn__EditarActividad d-flex align-items-center justify-content-center p-0 btn btn-success rounded-circle me-2'>
                                                                                                                                <i className="bi bi-pencil"></i>
                                                                                                                            </button>
                                                                                                                            <button disabled className='btn__DeleteActividad d-flex align-items-center justify-content-center p-0 btn btn-danger rounded-circle'>
                                                                                                                                <i className="bi bi-trash3"></i>
                                                                                                                            </button>
                                                                                                                        </>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <button onClick={() => handleEditarActividad(e.id_Actividades_Kr)} className='btn__EditarActividad d-flex align-items-center justify-content-center p-0 btn btn-success rounded-circle me-2'>
                                                                                                                                <i className="bi bi-pencil"></i>
                                                                                                                            </button>
                                                                                                                            <button onClick={() => handleDeleteActividad(e.id_Actividades_Kr)} className='btn__DeleteActividad d-flex align-items-center justify-content-center p-0 btn btn-danger rounded-circle'>
                                                                                                                                <i className="bi bi-trash3"></i>
                                                                                                                            </button>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                        {errorChangeHito !== null && <span className='hito--error align-self-center text-danger my-2'>
                                                                                            <i className="bi bi-exclamation-circle me-1"></i>{errorChangeHito}
                                                                                        </span>}
                                                                                        {/* AGREGAR NUEVO HITO */}
                                                                                        {(krSelected.mailUser === USER.mail || (USER.puesto < 5 && USER.puesto >= 1))  && (
                                                                                            <div className='addHito__container'>
                                                                                                <div className='d-flex flex-row align-items-center'>
                                                                                                    <div className='me-2'>
                                                                                                        <label className='mb-1'>Ingresa una actividad</label>
                                                                                                        <input 
                                                                                                            onChange={(e) => setNewHito(e.target.value)}
                                                                                                            type='text'
                                                                                                            id='hito'
                                                                                                            name='hito'
                                                                                                            className="input__text--addHito form-control form-control-sm"
                                                                                                            value={newHito} 
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='me-2'>
                                                                                                        <label className='mb-1'>Fecha límite</label>
                                                                                                        <input
                                                                                                            onChange={(e) => setFechaHito(e.target.value)}
                                                                                                            type="date" 
                                                                                                            id="fechaHito" 
                                                                                                            name="fechaHito" 
                                                                                                            className="input__date--addHito form-control form-control-sm"
                                                                                                            value={fechaHito}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='align-self-end mb-1'>
                                                                                                        <button onClick={handleAddNewHito} className='btn__addHito d-flex align-items-center justify-content-center p-0 btn btn-primary rounded-circle'>
                                                                                                            <i className="bi bi-plus-lg"></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {errorHito !== null && <span className='hito--error align-self-center text-danger my-2'>
                                                                                                    <i className="bi bi-exclamation-circle me-1"></i>{errorHito}
                                                                                                </span>}
                                                                                            </div>
                                                                                        )}
                                                                                        
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </section>
                                                <aside className='KRDetalle__main__extras'>
                                                    {loading ? (
                                                        <div className='loading__listaOkr d-flex flex-column align-items-center justify-content-center'>
                                                            <Oval
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                color="#0d6efd"
                                                                ariaLabel="oval-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                            <p className='fw-medium'>Loading...</p>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {error ? (
                                                                <div className='okr__error d-flex flex-column align-items-center justify-content-center rounded-3 mb-4'>
                                                                    <h3 className='text-danger mb-1'>Oops!</h3>
                                                                    <p className='m-0'>{error}</p>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    {krSelected && <>
                                                                        {/* Responsable */}
                                                                        <p className='mb-1 fw-semibold'>Responsable</p>
                                                                        <div className='d-flex flex-row align-items-center mb-3'>
                                                                            <img className='me-1 avatar__aside' src={Avatar} alt='avatar' />
                                                                            <p className='m-0'>{krSelected.nombreUser}</p>
                                                                        </div>
                                                                        {/* Fecha de inicio */}
                                                                        <p className='m-0 fw-semibold'>Fecha de inicio</p>
                                                                        <p className='mb-3'>{krSelected.fecha_KeyResult.replace(/-/g, '/').split("/").reverse().join("/")}</p>
                                                                        {/* Frecuencia */}
                                                                        {krSelected.tipo === 1 && (
                                                                            <>
                                                                                <p className='m-0 fw-semibold'>Frecuencia</p>
                                                                                <p className='mb-3'>
                                                                                    {krSelected.frecuencia === 1 && 'Mensual'}
                                                                                    {krSelected.frecuencia === 2 && 'Bimestral'}
                                                                                    {krSelected.frecuencia === 3 && 'Trimestral'}
                                                                                </p>
                                                                            </>
                                                                        )}
                                                                        {/* Prioridad */}
                                                                        <p className='m-0 fw-semibold'>Prioridad</p>
                                                                        <div>
                                                                            {krSelected.prioridad === 1 && <span className='badge--baja rounded-pill text-white badge'>baja</span>}
                                                                            {krSelected.prioridad === 2 && <span className='badge--media rounded-pill text-white badge'>media</span>}
                                                                            {krSelected.prioridad === 3 && <span className='badge--alta rounded-pill text-white badge'>alta</span>}
                                                                        </div>
                                                                    </>}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </aside>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </keyResultContext.Provider>
        </>
    )
}

export default DetalleKeyResult