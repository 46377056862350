import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../../assets/img/access.png"
import "../../pages/PlanesAccion/Proyectos.scss"

function ListaAreasAudit(props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [areas, setAreas] = useState([]);

  useEffect(() => {
    const fetchAreas = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/areasView`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            user: props.user,
          })
        })
        const data = await res.json()
        if(data.status !== 0){
          setError(data.codeError)
          setLoading(false)
        } else {
          setAreas(data.objeto)
          setLoading(false)
        }
      } catch (error) {
        setError("Error: Conexión con backend.")
        setLoading(false)
      }
    }

    fetchAreas()
  }, [])

  return (
    <>
      {loading ? (
        <div className='loading__areas d-flex flex-column align-items-center justify-content-center'>
          <Oval
              visible={true}
              height="80"
              width="80"
              color="#0d6efd"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
          />
          <p className='fw-medium'>Loading...</p>
        </div>
      ) : (
        <>
          {error ? (
            <div className='areas__proyectos--error d-flex flex-column align-items-center justify-content-center rounded-3'>
              <img className='mb-4' src={IllustrationAccess} alt="" />
              <h2 className='text-danger fw-semibold mb-1 text-center'>Oops!</h2>
              <p className='text-center'>{error}</p>
              <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
            </div>
          ) : (
            <>
              {areas.length === 0 ? (
                <div className='areas__proyectos--empty d-flex flex-column align-items-center justify-content-center'>
                  <img className='mb-4' src={IllustrationAccess} alt="" />
                  <h3 className='text-blue-500 fw-semibold mb-1 text-center'>No tienes áreas asignadas.</h3>
                  <p className='text-center'>Contactar al equipo de soporte para el manejo de áreas.</p>
                  <Link className='btn btn-primary rounded-pill shadow-sm fw-medium px-3' to={"/home"}>Regresar al home</Link>
                </div>
              ) : (
                <div className='areas__list mb-4'>
                    {areas.map((e,i) => {
                        return <Link to={`/plan-de-auditoria/area/${e.id_area}`} className="btn areas__list__area border border-light-subtle shadow-sm rounded-3 d-flex flex-row align-items-center" key={i}>
                            <h4 className='p-0 m-0 text-start'>{e.nombre_del_Area}</h4>
                        </Link>
                    })}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default ListaAreasAudit