import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import { jwtDecode } from "jwt-decode"
import { Oval } from 'react-loader-spinner'
import IllustrationAccess from "../assets/img/access.png"
import "./PowerBI.scss"

function PowerBI() {
    const { idArea } = useParams()

    const [data, setData] = useState("")
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {

        const auth = localStorage.getItem("token")
        const jwtParse = jwtDecode(auth)
        const USER = jwtParse.apirest.objeto

        const fetchData = async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/apis/index/biAreas`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        user: USER,
                        area: parseInt(idArea)
                    })
                })
                const data = await res.json()
                if(data.status !== 0){
                    setError(data.codeError)
                    setLoading(false)
                } else {
                    setData(data.objeto.BiArea)
                    setLoading(false)
                }
            } catch (error) {
                setError("Error: Conexión con backend.")
                setLoading(false)
            }
        }
        
        fetchData()
      }, [idArea])

    return (
        <>
            {loading ? (
                <div className='loading__powerbi d-flex flex-column align-items-center justify-content-center'>
                    <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="#0d6efd"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    <p className='fw-medium'>Loading...</p>
                </div>
            ): (
                <>
                    {error ? (
                        <div className='error no__access d-flex flex-column align-items-center justify-content-center'>
                            <img className='mb-4' src={IllustrationAccess} alt="" />
                            <h2 className='fw-semibold mb-2'>Oops!</h2>
                            <p>{error}</p>
                            <Link className='btn shadow-sm fw-medium' to={"/home"}>Home</Link>
                        </div>
                    ) : (
                        <>
                            {data === null ? (
                                <div className='null no__access d-flex flex-column align-items-center justify-content-center'>
                                    <img className='mb-4' src={IllustrationAccess} alt="" />
                                    <h2 className='fw-semibold mb-2'>Oops!</h2>
                                    <p>No se encontró un tablero de Power BI.</p>
                                    <Link className='btn shadow-sm fw-medium' to={"/home"}>Home</Link>
                                </div>
                            ) : (
                                <iframe 
                                    className="powerBi border-0" 
                                    title="TABLERO-GLOBAL_HITSS (2)" 
                                    src={data} 
                                    allowFullScreen={true}> 
                                </iframe>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default PowerBI